@media screen and (min-width: 1400px) {
    .home-events-inner-container {
        width: 1300px;
        margin: 0 auto;
    }

    .home-events-headline {
        font-family: "Istok Regular", sans-serif;
        font-size: 30px;
        text-transform: uppercase;
        line-height: 35px;
        letter-spacing: 7px;
        color: #E3001B;
        text-align: center;
        padding-top: 38px;
        padding-bottom: 38px;
    }

    .home-events-page-container-single {
        display: inline-block;
        width: 388px;
        vertical-align: top;
        padding-right: 25px;
        padding-left: 25px;
        border-right: 1px solid #9A9A9A;
    }

    .home-last-element-on-row {
        padding-right: 0;
        border-right: none;
    }

    .home-events-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #E3001B;
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 15px;
    }

    .home-events-page-container-single-download {
        margin-top: 20px;
        min-height: 44px;
    }

    .home-events-page-container-single-download-link {
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .home-events-more-button {
        display: block;
        margin: 40px auto 60px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        min-width: 144px;
        max-width: 240px;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }

    .home-events-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .home-events-inner-container {
        width: 1100px;
        margin: 0 auto;
    }

    .home-events-headline {
        font-family: "Istok Regular", sans-serif;
        font-size: 30px;
        text-transform: uppercase;
        line-height: 35px;
        letter-spacing: 7px;
        color: #E3001B;
        text-align: center;
        padding-top: 38px;
        padding-bottom: 38px;
    }

    .home-events-page-container-single {
        display: inline-block;
        width: 336px;
        vertical-align: top;
        border-right: 1px solid #9A9A9A;
        padding-right: 15px;
        margin-right: 15px;
        padding-left:0px;
    }

    .home-last-element-on-row {
        padding-right: 0;
        border-right: none;
    }

    .home-events-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #E3001B;
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 15px;
    }

    .home-events-page-container-single-download {
        margin-top: 20px;
        min-height: 44px;
    }

    .home-events-page-container-single-download-link {
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .home-events-more-button {
        display: block;
        margin: 40px auto 60px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        min-width: 144px;
        max-width: 240px;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }

    .home-events-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }
}


/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .home-events-inner-container {
        width: 922px;
        margin: 0 auto;
    }

    .home-events-headline {
        font-family: "Istok Regular", sans-serif;
        font-size: 30px;
        text-transform: uppercase;
        line-height: 35px;
        letter-spacing: 7px;
        color: #E3001B;
        text-align: center;
        padding-top: 38px;
        padding-bottom: 38px;
    }

    .home-events-page-container-single {
        display: inline-block;
        width: 246px;
        vertical-align: top;
        border-right: 1px solid #9A9A9A;
        padding-right: 33px;
        margin-right: 33px;
    }

    .home-last-element-on-row {
        padding-right: 0;
        border-right: none;
    }

    .home-events-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #E3001B;
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 15px;
    }

    .home-events-page-container-single-download {
        margin-top: 20px;
        min-height: 44px;
    }

    .home-events-page-container-single-download-link {
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .home-events-more-button {
        display: block;
        margin: 40px auto 60px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        min-width: 144px;
        max-width: 240px;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }

    .home-events-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .home-events-inner-container {
        width: 666px;
        margin: 0 auto;
    }

    .home-events-headline {
        font-family: "Istok Regular", sans-serif;
        font-size: 30px;
        text-transform: uppercase;
        line-height: 35px;
        letter-spacing: 7px;
        color: #E3001B;
        text-align: center;
        padding-top: 38px;
        padding-bottom: 38px;
    }

    /* Decide if it's going to be blocks or 3 at the same time */
    .home-events-page-container-single {
        display: block;
        width: 100%;
    }

    .home-last-element-on-row {
        padding-right: 0;
        border-right: none;
    }

    .home-events-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 24px;
        line-height: 26px;
        color: #E3001B;
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 15px;
    }

    .home-events-page-container-single-download {
        margin-top: 20px;
        min-height: 44px;
    }

    .home-events-page-container-single-download-link {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding-top: 15px;
        padding-bottom: 20px;
        text-decoration: none;

    }

    .home-events-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        -ms-transition: .4s;
        -o-transition: .4s;
        transition: .4s;
        border: 1px solid #e3001b;
        color: #e3001b;
        max-width: 100%;
        font-family: "Istok Regular", sans-serif;
        font-size: 26px;
        line-height: 40px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
        margin-bottom: 60px;
    }

    .home-events-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }

}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .home-events-inner-container {
        width: 428px;
        margin: 0 auto;
    }

    .home-events-headline {
        font-family: "Istok Regular", sans-serif;
        font-size: 30px;
        text-transform: uppercase;
        line-height: 35px;
        letter-spacing: 7px;
        color: #E3001B;
        text-align: center;
        padding-top: 38px;
        padding-bottom: 38px;
    }

    .home-events-page-container-single {
        display: block;
        padding-top: 40px;
        padding-bottom: 40px;
        margin: 0 auto;
    }

    .home-last-element-on-row {
        padding-right: 0;
        border-right: none;
    }

    .home-events-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 15px;
    }

    .home-events-page-container-single-download {
        margin-top: 20px;
        min-height: 44px;
    }

    .home-events-page-container-single-download-link {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding-top: 15px;
        padding-bottom: 20px;
        text-decoration: none;

    }

    .home-events-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        -ms-transition: .4s;
        -o-transition: .4s;
        transition: .4s;
        border: 1px solid;
        color: #9A9A9A;
        max-width: 428px;
        font-family: "Istok Regular", sans-serif;
        font-size: 26px;
        line-height: 40px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
        margin: 0 auto;
        margin-bottom: 60px;
    }

    .home-events-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .home-events-inner-container {
        width: 300px;
        margin: 0 auto;
    }

    .home-events-headline {
        font-family: "Istok Regular", sans-serif;
        font-size: 22px;
        text-transform: uppercase;
        line-height: 35px;
        letter-spacing: 6.6px;
        color: #e3001b;
        text-align: center;
        padding-top: 38px;
        padding-bottom: 38px;
    }

    /* Decide if it's going to be blocks or 3 at the same time */
    .home-events-page-container-single {
        display: block;
        padding-top: 40px;
        padding-bottom: 40px;
        margin: 0 auto;
    }

    .home-last-element-on-row {
        padding-right: 0;
        border-right: none;
    }

    .home-events-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #E3001B;
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 15px;
    }

    .home-events-page-container-single-download {
        margin-top: 20px;
        min-height: 44px;
    }

    .home-events-page-container-single-download-link {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding-top: 15px;
        padding-bottom: 20px;
        text-decoration: none;

    }

    .home-events-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        -ms-transition: .4s;
        -o-transition: .4s;
        transition: .4s;
        border: 1px solid #e3001b;
        color: #e3001b;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
        margin: 0 auto;
        margin-bottom: 60px;
    }


}

@media screen and (max-width: 350px) {
    .home-events-inner-container {
        width:300px;
        margin: 0 auto;
    }

    .home-events-headline {
        font-family: "Istok Regular", sans-serif;
        font-size: 22px;
        text-transform: uppercase;
        line-height: 35px;
        letter-spacing: 6.6px;
        color: #e3001b;
        text-align: center;
        padding-top: 38px;
        padding-bottom: 38px;
    }

    /* Decide if it's going to be blocks or 3 at the same time */
    .home-events-page-container-single {
        display: block;
        padding-top: 40px;
        padding-bottom: 40px;
        margin: 0 auto;
    }

    .home-last-element-on-row {
        padding-right: 0;
        border-right: none;
    }

    .home-events-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #E3001B;
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 15px;
    }

    .home-events-page-container-single-download {
        margin-top: 20px;
        min-height: 44px;
    }

    .home-events-page-container-single-download-link {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding-top: 15px;
        padding-bottom: 20px;
        text-decoration: none;

    }

    .home-events-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        -ms-transition: .4s;
        -o-transition: .4s;
        transition: .4s;
        border: 1px solid #e3001b;
        color: #e3001b;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
        margin: 0 auto;
        margin-bottom: 60px;
    }


}

@media print {
    .home-events-inner-container {
        width: 1300px;
        margin: 0 auto;
    }

    .home-events-headline {
        font-family: "Istok Regular", sans-serif;
        font-size: 30px;
        text-transform: uppercase;
        line-height: 35px;
        letter-spacing: 7px;
        color: #E3001B;
        text-align: center;
        padding-top: 38px;
        padding-bottom: 38px;
    }

    .home-events-page-container-single {
        display: inline-block;
        width: 388px;
        vertical-align: top;
        padding-right: 25px;
        padding-left: 25px;
        border-right: 1px solid #9A9A9A;
    }

    .home-last-element-on-row {
        padding-right: 0;
        border-right: none;
    }

    .home-events-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #E3001B;
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 15px;
    }

    .home-events-page-container-single-download {
        margin-top: 20px;
        min-height: 44px;
    }

    .home-events-page-container-single-download-link {
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .home-events-more-button {
        display: block;
        margin: 40px auto 60px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        min-width: 144px;
        max-width: 240px;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }

    .home-events-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }
}