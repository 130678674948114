@media screen and (min-width: 1400px){
    .events-page-container {
        width: 1300px;
        margin: 40px auto 60px;
    }

    .events-page-container-single {
        display: inline-block;
        width: 350px;
        vertical-align: top;
        padding-right: 25px;
        padding-left: 25px;
        border-right: 1px solid #9A9A9A;
    }

    .last-element-on-row {
        margin-right: 0;
        border-right: none;
    }

    .events-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 22px;
        color: #E3001B;
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 15px;
    }

    .events-page-container-single-download {
        margin-top: 20px;
        min-height: 44px;
    }

    .events-page-container-single-download-link {
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .events-page-container-single-separator {
        height: 1px;
        background: #676767;
        margin: 40px 88px 40px 25px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .events-page-container {
        width: 1100px;
        margin: 40px auto 60px;
    }

    .events-page-container-single {
        display: inline-block;
        width: 300px;
        vertical-align: top;
        padding: 0px 25px;
        border-right: 1px solid #9A9A9A;
    }

    .last-element-on-row {
        margin-right: 0;
        border-right: none;
    }

    .events-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 22px;
        color: #E3001B;
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 15px;
    }

    .events-page-container-single-download {
        margin-top: 20px;
        min-height: 44px;
    }

    .events-page-container-single-download-link {
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .events-page-container-single-separator {
        height: 1px;
        background: #676767;
        margin: 40px 88px 40px 25px;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .events-page-container {
        width: 922px;
        margin: 40px auto 60px;
    }

    .events-page-container-single {
        display: inline-block;
        width: 260px;
        vertical-align: top;
        padding: 0px 25px;
        border-right: 1px solid #9A9A9A;
    }

    .last-element-on-row {
        margin-right: 0;
        border-right: none;
        padding-right:0px;
    }

    .events-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 22px;
        color: #E3001B;
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 15px;
    }

    .events-page-container-single-download {
        margin-top: 20px;
        min-height: 44px;
    }

    .events-page-container-single-download-link {
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .events-page-container-single-separator {
        height: 1px;
        background: #676767;
        margin: 40px 88px 40px 25px;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .events-page-container {
        width: 666px;
        margin: 40px auto 60px;
    }

    .events-page-container-single {
        display: inline-block;
        width: 200px;
        vertical-align: top;
        height:120px;
        padding: 0px 11px;
        border-right: 1px solid #9A9A9A;
    }

    .last-element-on-row {
        margin-right: 0;
        border-right: none;
        padding-right:0px;
    }

    .events-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 22px;
        color: #E3001B;
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 15px;
    }

    .events-page-container-single-download {
        margin-top: 20px;
        min-height: 44px;
    }

    .events-page-container-single-download-link {
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .events-page-container-single-separator {
        height: 1px;
        background: #676767;
        margin: 40px 88px 40px 25px;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .events-page-container {
        width: 428px;
        margin: 40px auto 60px;
    }

    .events-page-container-single {
        display: inline-block;
        width: 208px;
        vertical-align: top;
        height:120px;
        padding: 0px 0px;
        padding-right:5px;
        border-right: 1px solid #9A9A9A;
    }

    
    .events-page-container-single:nth-child(2){
        border:none;
        padding-right:0px;
    }
    .events-page-container-single:nth-child(5){
        border:none;
        padding-right:0px;
    }
    .events-page-container-single:nth-child(7){
        border:none;
        padding-right:0px;
    }
    .events-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 18px;
        line-height: 22px;
        color: #E3001B;
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 15px;
    }

    .events-page-container-single-download {
        margin-top: 20px;
        min-height: 44px;
    }

    .events-page-container-single-download-link {
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .events-page-container-single-separator {
        display: none;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .events-page-container {
        width: 300px;
        margin: 40px auto 60px;
        padding-bottom:20px;
    }

    .events-page-container-single {
        display: block;
        width: 100%;
        vertical-align: top;
    }

    
   
    .events-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 18px;
        line-height: 22px;
        color: #E3001B;
        border-bottom: 1px solid #9A9A9A;
    }

    .events-page-container-single-download {
        margin-top: 20px;
        min-height: 44px;
    }

    .events-page-container-single-download-link {
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .events-page-container-single-separator {
        display: none;
    }
}

@media screen and (max-width: 350px){
    .events-page-container {
        width: 300px;
        margin: 40px auto 60px;
    }

    .events-page-container-single {
        display: block;
        width: 100%;
        vertical-align: top;
        padding-bottom:20px;
    }

    
   
    .events-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 18px;
        line-height: 22px;
        color: #E3001B;
        border-bottom: 1px solid #9A9A9A;
    }

    .events-page-container-single-download {
        margin-top: 20px;
        min-height: 44px;
    }

    .events-page-container-single-download-link {
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .events-page-container-single-separator {
        display: none;
    }
}

@media print {
    .events-page-container {
        width: 1300px;
        margin: 40px auto 60px;
    }

    .events-page-container-single {
        display: inline-block;
        width: 350px;
        vertical-align: top;
        padding-right: 25px;
        padding-left: 25px;
        border-right: 1px solid #9A9A9A;
    }

    .last-element-on-row {
        margin-right: 0;
        border-right: none;
    }

    .events-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 22px;
        color: #E3001B;
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 15px;
    }

    .events-page-container-single-download {
        margin-top: 20px;
        min-height: 44px;
    }

    .events-page-container-single-download-link {
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .events-page-container-single-separator {
        height: 1px;
        background: #676767;
        margin: 40px 88px 40px 25px;
    }
}