@media screen and (min-width: 1400px) {
    .pageImageText-image {
        width: 186px;
        height: 64px;
        display: block;
    }

    .pageImageTextContainer {
        margin-bottom: 50px;
    }

    .pageImageText-link {
        display: block;
        padding-top: 16px;
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        text-transform: uppercase;
        line-height: 22px;
        letter-spacing: 1px;
        color: #9A9A9A;
        width: 186px;
        border-bottom: 1px solid #9A9A9A;
    }

    .pageImageText-link-title {
        display: inline-block;
    }

    .pageImageText-link i {
        display: inline-block;
        font-weight: 400;
        padding-left: 40px;
        font-size: 20px;
        vertical-align: middle;
    }

    .pageImageTextLeftSide {
        display: inline-block;
        width: 260px;
        vertical-align: top;
    }

    .pageImageText-description {
        display: inline-block;
        width: 600px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        vertical-align: top;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .pageImageText-image {
        width: 186px;
        height: 64px;
        display: block;
    }

    .pageImageTextContainer {
        margin-bottom: 50px;
    }

    .pageImageText-link {
        display: block;
        padding-top: 16px;
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        text-transform: uppercase;
        line-height: 22px;
        letter-spacing: 1px;
        color: #9A9A9A;
        width: 186px;
        border-bottom: 1px solid #9A9A9A;
    }

    .pageImageText-link-title {
        display: inline-block;
    }

    .pageImageText-link i {
        display: inline-block;
        font-weight: 400;
        padding-left: 40px;
        font-size: 20px;
        vertical-align: middle;
    }

    .pageImageTextLeftSide {
        display: inline-block;
        width: 260px;
        vertical-align: top;
    }

    .pageImageText-description {
        display: inline-block;
        width: 440px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        vertical-align: top;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .pageImageText-image {
        width: 186px;
        height: 64px;
        display: block;
    }

    .pageImageTextContainer {
        margin-bottom: 50px;
    }

    .pageImageText-link {
        display: block;
        padding-top: 16px;
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        text-transform: uppercase;
        line-height: 22px;
        letter-spacing: 1px;
        color: #9A9A9A;
        width: 186px;
        border-bottom: 1px solid #9A9A9A;
    }

    .pageImageText-link-title {
        display: inline-block;
    }

    .pageImageText-link i {
        display: inline-block;
        font-weight: 400;
        padding-left: 40px;
        font-size: 20px;
        vertical-align: middle;
    }

    .pageImageTextLeftSide {
        display: inline-block;
        width: 200px;
        vertical-align: top;
    }

    .pageImageText-description {
        display: inline-block;
        width: 400px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        vertical-align: top;
        margin-left:52px;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .pageImageText-image {
        width: 150px;
        height: 52px;
        display: block;
    }

    .pageImageTextContainer {
        margin-bottom: 50px;
    }

    .pageImageText-link {
        display: block;
        padding-top: 16px;
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        text-transform: uppercase;
        line-height: 22px;
        letter-spacing: 1px;
        color: #9A9A9A;
        width: 150px;
        border-bottom: 1px solid #9A9A9A;
    }

    .pageImageText-link-title {
        display: inline-block;
    }

    .pageImageText-link i {
        display: inline-block;
        font-weight: 400;
        padding-left: 4px;
        font-size: 20px;
        vertical-align: middle;
    }

    .pageImageTextLeftSide {
        display: inline-block;
        width: 200px;
        vertical-align: top;
    }

    .pageImageText-description {
        display: inline-block;
        width: 280px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        vertical-align: top;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .pageImageText-image {
        width: 186px;
        height: 64px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 38px;
    }
    .pageImageTextContainer {
        margin-bottom: 50px;
    }
    
    .pageImageText-link {
        display: inline-block;
        padding-top: 16px;
        text-decoration: none;
        font-family: "Istok Regular",sans-serif;
        font-size: 15px;
        text-transform: uppercase;
        line-height: 22px;
        letter-spacing: 1px;
        color: #9a9a9a;
        vertical-align: middle;
        width: 200px;
        border-bottom: 1px solid #9a9a9a;
    }
    
    .pageImageText-link-title {
        display: inline-block;
    }
    
    .pageImageText-link i {
        display: inline-block;
        font-weight: 400;
        padding-left: 4px;
        font-size: 20px;
        vertical-align: middle;
    }
    
    .pageImageTextLeftSide {
        display: inline-block;
        width: 100%;
        vertical-align: top;
    }
    
    .pageImageText-description {
        display: inline-block;
        width: 428px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        vertical-align: top;
        padding-top: 20px;;
    }
}




@media screen and (min-width: 350px) and (max-width: 478px) {
    .pageImageText-image {
        width: 110px;
        height: 40px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
    }
    .pageImageTextContainer {
        margin-bottom: 50px;
    }
    
    .pageImageText-link {
        display: inline-block;
        text-decoration: none;
        font-family: "Istok Regular",sans-serif;
        font-size: 16px;
        text-transform: uppercase;
        line-height: 22px;
        letter-spacing: 1px;
        color: #9a9a9a;
        vertical-align: middle;
        border-bottom: 1px solid #9a9a9a;
    }
    
    .pageImageText-link-title {
        display: inline-block;
    }
    
    .pageImageText-link i {
        display: inline-block;
        font-weight: 400;
        padding-left: 4px;
        font-size: 16px;
        vertical-align: middle;
    }
    
    .pageImageTextLeftSide {
        display: inline-block;
        width: 100%;
        vertical-align: top;
    }
    
    .pageImageText-description {
        display: inline-block;
        width: 300px;
        font-family: "Istok Regular", sans-serif;
        font-size: 14px;
        line-height: 22px;
        color: #676767;
        vertical-align: top;
        padding-top: 20px;;
    }
}

@media screen and (max-width: 350px) {
    .pageImageText-image {
        width: 120px;
        height: 40px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
    }
    .pageImageTextContainer {
        margin-bottom: 50px;
    }
    
    .pageImageText-link {
        display: inline-block;
        padding-top: 16px;
        text-decoration: none;
        font-family: "Istok Regular",sans-serif;
        font-size: 14px;
        text-transform: uppercase;
        line-height: 22px;
        letter-spacing: 1px;
        color: #9a9a9a;
        vertical-align: middle;
        width: 160px;
        border-bottom: 1px solid #9a9a9a;
    }
    
    .pageImageText-link-title {
        display: inline-block;
    }
    
    .pageImageText-link i {
        display: inline-block;
        font-weight: 400;
        padding-left: 4px;
        font-size: 16px;
        vertical-align: middle;
    }
    
    .pageImageTextLeftSide {
        display: inline-block;
        width: 100%;
        vertical-align: top;
    }
    
    .pageImageText-description {
        display: inline-block;
        width: 300px;
        font-family: "Istok Regular", sans-serif;
        font-size: 14px;
        line-height: 22px;
        color: #676767;
        vertical-align: top;
        padding-top: 20px;;
    }
}

@media print {
    .pageImageText-image {
        width: 186px;
        height: 64px;
        display: block;
    }

    .pageImageTextContainer {
        margin-bottom: 50px;
    }

    .pageImageText-link {
        display: block;
        padding-top: 16px;
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        text-transform: uppercase;
        line-height: 22px;
        letter-spacing: 1px;
        color: #9A9A9A;
        width: 186px;
        border-bottom: 1px solid #9A9A9A;
    }

    .pageImageText-link-title {
        display: inline-block;
    }

    .pageImageText-link i {
        display: inline-block;
        font-weight: 400;
        padding-left: 40px;
        font-size: 20px;
        vertical-align: middle;
    }

    .pageImageTextLeftSide {
        display: inline-block;
        width: 260px;
        vertical-align: top;
    }

    .pageImageText-description {
        display: inline-block;
        width: 600px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        vertical-align: top;
    }
}