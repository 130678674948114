@media screen and (min-width: 1400px) {
    #footer {
        background: #9A9A9A;
        height: 50px;
    }

    #footerContainer {
        width: 1300px;
        margin: 0 auto;
    }

    #footerLinks {
        display: inline-block;
        margin-top: 7px;
    }

    .footerLinks {
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
    }

    .footerLinksSeparator {
        display: inline-block;
        color: white;
        padding: 5px;
    }

    #footerRightSide {
        display: inline-block;
        float: right;
        margin-top: 7px;
    }

    .footerCopyrightName {
        display: inline-block;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
        padding-right: 26px;
    }

    .footerCopyrightName i {
        padding-right: 6px;
    }

    .footerSocialLinks {
        display: inline-block;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
    }

    .footerSocialLinks i {
        padding-left: 12px;
        font-size: 22px;
        vertical-align: top;
        margin-top: 7px;
        color: white;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    #footer {
        background: #9A9A9A;
        height: 50px;
    }

    #footerContainer {
        width: 1100px;
        margin: 0 auto;
    }

    #footerLinks {
        display: inline-block;
        margin-top: 7px;
    }

    .footerLinks {
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
    }

    .footerLinksSeparator {
        display: inline-block;
        color: white;
        padding: 5px;
    }

    #footerRightSide {
        display: inline-block;
        float: right;
        margin-top: 7px;
    }

    .footerCopyrightName {
        display: inline-block;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
        padding-right: 26px;
    }

    .footerCopyrightName i {
        padding-right: 6px;
    }

    .footerSocialLinks {
        display: inline-block;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
    }

    .footerSocialLinks i {
        padding-left: 12px;
        font-size: 22px;
        vertical-align: top;
        margin-top: 7px;
        color: white;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #footer {
        background: #9A9A9A;
        height: 50px;
    }

    #footerContainer {
        width: 922px;
        margin: 0 auto;
    }

    #footerLinks {
        display: inline-block;
        margin-top: 7px;
    }

    .footerLinks {
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
    }

    .footerLinksSeparator {
        display: inline-block;
        color: white;
        padding: 5px;
    }

    #footerRightSide {
        display: inline-block;
        float: right;
        margin-top: 7px;
    }

    .footerCopyrightName {
        display: inline-block;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
        padding-right: 26px;
    }

    .footerCopyrightName i {
        padding-right: 6px;
    }

    .footerSocialLinks {
        display: inline-block;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
    }

    .footerSocialLinks i {
        padding-left: 12px;
        font-size: 22px;
        vertical-align: top;
        margin-top: 7px;
        color: white;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #footer {
        background: #9A9A9A;
        height: 50px;
    }

    #footerContainer {
        width: 666px;
        margin: 0 auto;
    }

    #footerLinks {
        display: inline-block;
        margin-top: 7px;
    }

    .footerLinks {
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
    }

    .footerLinksSeparator {
        display: inline-block;
        color: white;
        padding: 5px;
    }

    #footerRightSide {
        display: inline-block;
        float: right;
        margin-top: 7px;
    }

    .footerCopyrightName {
        display: inline-block;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
        padding-right: 8px;
    }

    .footerCopyrightName i {
        padding-right: 6px;
    }

    .footerSocialLinks {
        display: inline-block;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
    }

    .footerSocialLinks i {
        padding-left: 12px;
        font-size: 22px;
        vertical-align: top;
        margin-top: 7px;
        color: white;
    }

}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #footer {
        background: #9A9A9A;
        height: 90px;
    }

    #footerContainer {
        width: 428px;
        margin: 0 auto;
    }

    #footerLinks {
        display: inline-block;
        margin-top: 7px;
    }

    .footerLinks {
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
    }

    .footerLinksSeparator {
        display: inline-block;
        color: white;
        padding: 10px;
    }

    #footerRightSide {
        display: block;
        margin: 0 auto;
        margin-top: 7px;
    }

    .footerCopyrightName {
        display: inline-block;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
        padding-right: 26px;
    }

    .footerCopyrightName i {
        padding-right: 6px;
    }

    .footerSocialLinks {
        display: inline-block;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
        float:right;
    }

    .footerSocialLinks i {
        padding-left: 12px;
        font-size: 22px;
        vertical-align: top;
        margin-top: 7px;
        color: white;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #footer {
        width: 100%;
        background: #9A9A9A;
        height: 90px;
    }

    #footerContainer {
        width: 300px;
        margin: 0 auto;
    }

    #footerLinks {
        display: block;
        padding-top:10px;
    }

    .footerLinks {
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 1;
    }

    .footerLinksSeparator {
        display: inline-block;
        color: white;
    }

    #footerRightSide {
        display: block;
        margin-top: 14px;
    }

    .footerCopyrightName {
        display: inline-block;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 14px;
        line-height: 35px;
    }

    .footerCopyrightName i {
        padding-right: 6px;
    }

    .footerSocialLinks {
        display: inline-block;
        color: #fff;
        font-family: "Istok Regular", sans-serif;
        font-size: 14px;
        line-height: 35px;
        margin-left:60px;
    }

    .footerSocialLinks i {
        padding-left: 12px;
        font-size: 22px;
        vertical-align: top;
        margin-top: 7px;
        color: white;
    }
}

@media screen and (max-width: 350px) {
    #footer {
        width: 100%;
        background: #9A9A9A;
        height: 90px;
    }

    #footerContainer {
        width: 300px;
        margin: 0 auto;
    }

    #footerLinks {
        display: block;
        padding-top:10px;
    }

    .footerLinks {
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 11px;
        line-height: 1;
    }

    .footerLinksSeparator {
        display: inline-block;
        color: white;
    }

    #footerRightSide {
        display: block;
        margin-top: 14px;
    }

    .footerCopyrightName {
        display: inline-block;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 14px;
        line-height: 35px;
    }

    .footerCopyrightName i {
        padding-right: 6px;
    }

    .footerSocialLinks {
        display: inline-block;
        color: #fff;
        font-family: "Istok Regular", sans-serif;
        font-size: 14px;
        line-height: 35px;
        position: relative;
        left: 24px;
    }

    .footerSocialLinks i {
        padding-left: 12px;
        font-size: 22px;
        vertical-align: top;
        margin-top: 7px;
        color: white;
    }
}

@media print {
    #footer {
        background: #9A9A9A;
        height: 50px;
    }

    #footerContainer {
        width: 1300px;
        margin: 0 auto;
    }

    #footerLinks {
        display: inline-block;
        margin-top: 7px;
    }

    .footerLinks {
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
    }

    .footerLinksSeparator {
        display: inline-block;
        color: white;
        padding: 5px;
    }

    #footerRightSide {
        display: inline-block;
        float: right;
        margin-top: 7px;
    }

    .footerCopyrightName {
        display: inline-block;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
        padding-right: 26px;
    }

    .footerCopyrightName i {
        padding-right: 6px;
    }

    .footerSocialLinks {
        display: inline-block;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
    }

    .footerSocialLinks i {
        padding-left: 12px;
        font-size: 22px;
        vertical-align: top;
        margin-top: 7px;
        color: white;
    }
}