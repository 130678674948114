/*set the font for the page*/
.ident-to-right{
    margin-left: 10px;
}
@media screen and (min-width: 1400px){ /*1300*/
    .sitemapContainer{
        width:1300px;
        margin: 0 auto;
        min-height:500px;
        font-family: "Istok Regular", sans-serif;
        color: #9A9A9A;
    }
    .sitemapPage-title{
        text-align: center;
        font-size: 30px;
        line-height: 46px;
        padding: 30px 0px;
        font-family: "Istok Bold", sans-serif;
    }
    .sitemapLinks{
        display:block;
        padding:8px 0px;
        text-decoration: none;
        color:#e3001b;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){ /*1100*/
    .sitemapContainer{
        width:1100px;
        margin: 0 auto;
        min-height:500px;
        font-family: "Istok Regular", sans-serif;
        color: #9A9A9A;
    }
    .sitemapPage-title{
        text-align: center;
        font-size: 30px;
        line-height: 46px;
        padding: 30px 0px;
        font-family: "Istok Bold", sans-serif;
    }
    .sitemapLinks{
        display:block;
        padding:8px 0px;
        text-decoration: none;
        color:#e3001b;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) { /*922*/
    .sitemapContainer{
        width:922px;
        margin: 0 auto;
        min-height:500px;
        font-family: "Istok Regular", sans-serif;
        color: #9A9A9A;
    }
    .sitemapPage-title{
        text-align: center;
        font-size: 30px;
        line-height: 46px;
        padding: 30px 0px;
        font-family: "Istok Bold", sans-serif;
    }
    .sitemapLinks{
        display:block;
        padding:8px 0px;
        text-decoration: none;
        color:#e3001b;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {/*666*/
    .sitemapContainer{
        width:666px;
        margin: 0 auto;
        min-height:500px;
        font-family: "Istok Regular", sans-serif;
        color: #9A9A9A;
    }
    .sitemapPage-title{
        text-align: center;
        font-size: 26px;
        line-height: 46px;
        padding: 30px 0px;
        font-family: "Istok Bold", sans-serif;
    }
    .sitemapLinks{
        display:block;
        padding:8px 0px;
        text-decoration: none;
        color:#e3001b;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {/*428*/
    .sitemapContainer{
        width:428px;
        margin: 0 auto;
        min-height:500px;
        font-family: "Istok Regular", sans-serif;
        color: #9A9A9A;
    }
    .sitemapPage-title{
        text-align: center;
        font-size: 26px;
        line-height: 46px;
        padding: 30px 0px;
        font-family: "Istok Bold", sans-serif;
    }
    .sitemapLinks{
        display:block;
        padding:8px 0px;
        text-decoration: none;
        color:#e3001b;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {/*300*/
    .sitemapContainer{
        width:320px;
        margin: 0 auto;
        min-height:500px;
        font-family: "Istok Regular", sans-serif;
        color: #9A9A9A;
    }
    .sitemapPage-title{
        text-align: center;
        font-size: 22px;
        line-height: 46px;
        padding: 10px 0px;
        font-family: "Istok Bold", sans-serif;
    }
    .sitemapLinks{
        display:block;
        padding:8px 0px;
        text-decoration: none;
        color:#e3001b;
    }
}

@media screen and (max-width: 350px){/*300*/
    .sitemapContainer{
        width:310px;
        margin: 0 auto;
        min-height:500px;
        font-family: "Istok Regular", sans-serif;
        color: #9A9A9A;
    }
    .sitemapPage-title{
        text-align: center;
        font-size: 22px;
        line-height: 46px;
        padding: 10px 0px;
        font-family: "Istok Bold", sans-serif;
    }
    .sitemapLinks{
        display:block;
        padding:8px 0px;
        text-decoration: none;
        color:#e3001b;
    }

}

@media print {
    .sitemapContainer{
        width:1300px;
        margin: 0 auto;
        min-height:500px;
        font-family: "Istok Regular", sans-serif;
        color: #9A9A9A;
    }
    .sitemapPage-title{
        text-align: center;
        font-size: 30px;
        line-height: 46px;
        padding: 30px 0px;
        font-family: "Istok Bold", sans-serif;
    }
    .sitemapLinks{
        display:block;
        padding:8px 0px;
        text-decoration: none;
        color:#e3001b;
    }
}