@media screen and (min-width: 1400px){
    .news-container {
        margin-top: 40px;
        margin-bottom: 40px;
        border-top: 1px solid #9A9A9A;
    }

    .news-row-container {
        border-bottom: 1px solid #9A9A9A;
    }

    .news-row-inner-container {
        width: 1300px;
        margin: 0 auto;
    }

    .news-page-container-single {
        width: 320px;
        display: inline-block;
        margin-right: 82px;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 82px;
        border-right: 1px solid #9A9A9A;
        vertical-align: top;
    }

    .news-last-element-on-row {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
    }

    .news-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 22px;
        color: #676767;
    }

    .news-page-container-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #e3001b;
        min-height: 60px;
    }

    .news-page-container-image {
        max-width: 320px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #9A9A9A;
    }

    .news-page-container-description {
        padding-top: 20px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        min-height: 90px;
    }

    .news-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        max-width: 144px;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }

    .news-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .news-container {
        margin-top: 40px;
        margin-bottom: 40px;
        border-top: 1px solid #9A9A9A;
    }

    .news-row-container {
        border-bottom: 1px solid #9A9A9A;
    }

    .news-row-inner-container {
        width: 1100px;
        margin: 0 auto;
    }

    .news-page-container-single {
        width: 320px;
        display: inline-block;
        margin-right: 32px;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 33px;
        border-right: 1px solid #9a9a9a;
        vertical-align: top;
    }

    .news-last-element-on-row {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
    }

    .news-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 22px;
        color: #676767;
    }

    .news-page-container-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #e3001b;
        min-height: 60px;
    }

    .news-page-container-image {
        max-width: 320px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #9A9A9A;
    }

    .news-page-container-description {
        padding-top: 20px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        min-height: 90px;
    }

    .news-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        max-width: 144px;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }

    .news-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .news-container {
        margin-top: 40px;
        margin-bottom: 40px;
        border-top: 1px solid #9A9A9A;
    }

    .news-row-container {
        border-bottom: 1px solid #9A9A9A;
    }

    .news-row-inner-container {
        width: 922px;
        margin: 0 auto;
    }

    .news-page-container-single {
        width: 260px;
        display: inline-block;
        margin-right: 32px;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 33px;
        border-right: 1px solid #9a9a9a;
        vertical-align: top;
    }

    .news-last-element-on-row {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
    }

    .news-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 22px;
        color: #676767;
    }

    .news-page-container-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #e3001b;
        min-height: 60px;
    }

    .news-page-container-image {
        max-width: 260px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #9A9A9A;
    }

    .news-page-container-description {
        padding-top: 20px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        min-height: 90px;
    }

    .news-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        max-width: 144px;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }

    .news-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .news-container {
        margin-top: 40px;
        margin-bottom: 40px;
        border-top: 1px solid #9A9A9A;
    }

    .news-row-container {
        border-bottom: 1px solid #9A9A9A;
    }

    .news-row-inner-container {
        width: 666px;
        margin: 0 auto;
    }

    .news-page-container-single {
        width: 300px;
        display: inline-block;
        margin-right: 32px;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 33px;
        border-right: 1px solid #9a9a9a;
        vertical-align: top;
    }
    .news-page-container-single:nth-child(2) {
        border:none;
        padding:40px 0px;
        margin:0;
        float:right;
        
    }

    .news-last-element-on-row {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
    }

    .news-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 22px;
        color: #676767;
    }

    .news-page-container-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #e3001b;
        min-height: 60px;
    }

    .news-page-container-image {
        max-width: 300px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #9A9A9A;
    }

    .news-page-container-description {
        padding-top: 20px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        min-height: 90px;
    }

    .news-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        max-width: 144px;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }

    .news-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .news-container {
        margin-bottom: 40px;
    }

    .news-row-container {
        border-bottom: 1px solid #9A9A9A;
    }

    .news-row-inner-container {
        width: 80%;
        margin: 0 auto;
    }

    .news-page-container-single {
        width: 100%;
        display: block;
        vertical-align: top;
        padding:40px 0px;
    }

    .news-last-element-on-row {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
    }

    .news-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding: 10px 0px;
        color: #676767;
    }

    .news-page-container-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 24px;
        line-height: 30px;
        color: #e3001b;
        min-height: 60px;
        padding-bottom:20px;
    }

    .news-page-container-image {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #9A9A9A;
    }

    .news-page-container-description {
        padding-top: 20px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        min-height: 90px;
        padding: 10px 0px;
    }

    .news-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        max-width: 100%;
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }

    
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .news-container {
        margin-bottom: 40px;
    }

    .news-row-container {
        border-bottom: 1px solid #9A9A9A;
    }

    .news-row-inner-container {
        width: 80%;
        margin: 0 auto;
    }

    .news-page-container-single {
        width: 100%;
        display: block;
        vertical-align: top;
        padding:40px 0px;
    }

    .news-last-element-on-row {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
    }

    .news-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding: 10px 0px;
        color: #676767;
    }

    .news-page-container-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 24px;
        line-height: 30px;
        color: #e3001b;
        min-height: 60px;
        padding-bottom:20px;
    }

    .news-page-container-image {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #9A9A9A;
    }

    .news-page-container-description {
        padding-top: 20px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        min-height: 90px;
        padding: 10px 0px;
    }

    .news-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        max-width: 100%;
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }
}

@media screen and (max-width: 350px){
    .news-container {
        margin-bottom: 40px;
    }

    .news-row-container {
        border-bottom: 1px solid #9A9A9A;
    }

    .news-row-inner-container {
        width: 80%;
        margin: 0 auto;
    }

    .news-page-container-single {
        width: 100%;
        display: block;
        vertical-align: top;
        padding:40px 0px;
    }

    .news-last-element-on-row {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
    }

    .news-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding: 10px 0px;
        color: #676767;
    }

    .news-page-container-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 24px;
        line-height: 30px;
        color: #e3001b;
        min-height: 60px;
        padding-bottom:20px;
    }

    .news-page-container-image {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #9A9A9A;
    }

    .news-page-container-description {
        padding-top: 20px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        min-height: 90px;
        padding: 10px 0px;
    }

    .news-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        max-width: 100%;
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }
}

@media print {
    .news-container {
        margin-top: 40px;
        margin-bottom: 40px;
        border-top: 1px solid #9A9A9A;
    }

    .news-row-container {
        border-bottom: 1px solid #9A9A9A;
    }

    .news-row-inner-container {
        width: 1300px;
        margin: 0 auto;
    }

    .news-page-container-single {
        width: 320px;
        display: inline-block;
        margin-right: 82px;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 82px;
        border-right: 1px solid #9A9A9A;
        vertical-align: top;
    }

    .news-last-element-on-row {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
    }

    .news-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 22px;
        color: #676767;
    }

    .news-page-container-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #e3001b;
        min-height: 60px;
    }

    .news-page-container-image {
        max-width: 320px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #9A9A9A;
    }

    .news-page-container-description {
        padding-top: 20px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        min-height: 90px;
    }

    .news-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        max-width: 144px;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }

    .news-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }
}