@media screen and (min-width: 1400px){
    .news-details {
        width: 1300px;
        margin: 60px auto;
    }

    .news-details-date {
        display: inline-block;
        width: 200px;
        vertical-align: top;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 22px;
        color: #676767;
        border-top: 1px solid #E3001B;
        padding-top: 20px;
    }

    .news-details-left-container {
        display: inline-block;
        width: 1000px;
        margin-left: 90px;
        vertical-align: top;
        text-align: left;
    }

    .news-details-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #E3001B;
        padding-bottom: 20px;
    }

    .news-details-description {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .news-details {
        width: 1100px;
        margin: 60px auto;
    }

    .news-details-date {
        display: inline-block;
        width: 180px;
        vertical-align: top;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 22px;
        color: #676767;
        border-top: 1px solid #E3001B;
        padding-top: 20px;
    }

    .news-details-left-container {
        display: inline-block;
        width: 850px;
        margin-left: 66px;
        vertical-align: top;
        text-align: left;
    }

    .news-details-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #E3001B;
        padding-bottom: 20px;
    }

    .news-details-description {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .news-details {
        width: 922px;
        margin: 60px auto;
    }

    .news-details-date {
        display: inline-block;
        width: 180px;
        vertical-align: top;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 22px;
        color: #676767;
        border-top: 1px solid #E3001B;
        padding-top: 20px;
    }

    .news-details-left-container {
        display: inline-block;
        width: 670px;
        margin-left: 66px;
        vertical-align: top;
        text-align: left;
    }

    .news-details-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #E3001B;
        padding-bottom: 20px;
    }

    .news-details-description {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .news-details {
        width: 666px;
        margin: 60px auto;
    }

    .news-details-date {
        display: inline-block;
        width: 100px;
        vertical-align: top;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 22px;
        color: #676767;
        border-top: 1px solid #E3001B;
        padding-top: 20px;
    }

    .news-details-left-container {
        display: inline-block;
        width: 500px;
        margin-left: 62px;
        vertical-align: top;
        text-align: left;
    }

    .news-details-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #E3001B;
        padding-bottom: 20px;
    }

    .news-details-description {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .news-details {
        width: 80%;
        margin: 60px auto;
    }

    .news-details-date {
        display: block;
        width: 100%;
        font-family: "Istok Bold", sans-serif;
        font-size: 18px;
        line-height: 24px;
        color: #676767;
        border-bottom: 1px solid #E3001B;
        margin-bottom: 20px;
    }

    .news-details-left-container {
        display: block;
        width: 100%;
        vertical-align: top;
    }

    .news-details-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 24px;
        line-height: 30px;
        color: #E3001B;
        padding-bottom: 20px;
    }

    .news-details-description {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .news-details {
        width: 80%;
        margin: 60px auto;
    }

    .news-details-date {
        display: block;
        width: 100%;
        font-family: "Istok Bold", sans-serif;
        font-size: 18px;
        line-height: 24px;
        color: #676767;
        border-bottom: 1px solid #E3001B;
        margin-bottom: 20px;
    }

    .news-details-left-container {
        display: block;
        width: 100%;
        vertical-align: top;
    }

    .news-details-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 24px;
        line-height: 30px;
        color: #E3001B;
        padding-bottom: 20px;
    }

    .news-details-description {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
    }
}

@media screen and (max-width: 350px){
    .news-details {
        width: 80%;
        margin: 60px auto;
    }

    .news-details-date {
        display: block;
        width: 100%;
        font-family: "Istok Bold", sans-serif;
        font-size: 18px;
        line-height: 24px;
        color: #676767;
        border-bottom: 1px solid #E3001B;
        margin-bottom: 20px;
    }

    .news-details-left-container {
        display: block;
        width: 100%;
        vertical-align: top;
    }

    .news-details-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 24px;
        line-height: 30px;
        color: #E3001B;
        padding-bottom: 20px;
    }

    .news-details-description {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
    }
}

@media print {
    .news-details {
        width: 1300px;
        margin: 60px auto;
    }

    .news-details-date {
        display: inline-block;
        width: 200px;
        vertical-align: top;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 22px;
        color: #676767;
        border-top: 1px solid #E3001B;
        padding-top: 20px;
    }

    .news-details-left-container {
        display: inline-block;
        width: 1000px;
        margin-left: 90px;
        vertical-align: top;
        text-align: left;
    }

    .news-details-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #E3001B;
        padding-bottom: 20px;
    }

    .news-details-description {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
    }
}