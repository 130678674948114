.isOpenMenu{
    display:inline-block;
}
.isClosedMenu{
    display:inline-block;
}
@media screen and (min-width: 1400px) {
    #header {
        width: 1300px;
        margin: 0 auto;
    }

    #headerLogo {
        display: inline-block;
    }

    #headerLogo img {
        width: 238px;
        height: 78px;
    }

    .headerRightSide {
        float: right;
        display: inline-block;
        padding-top: 22px;
    }

    #headerLinks {
        display: inline-block;
    }

    .headerLinks {
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 35px;
        text-decoration: none;
        padding: 12px;
    }
    .subLinksMenu {
        position: absolute;
        top: 48px;
        padding: 12px;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,.1), 0 4px 8px 0 rgba(0,0,0,.1);
        background-color: #fff;
        z-index: 9;
    }
    .subLinksMenu:hover{
        display:block;
    }
    .subLinksMenu a{
        display:block;
        text-align: center;
        padding:5px 0px;
        color: #676767;
        font-family: "Istok Regular",sans-serif;
        font-size: 13px;
        line-height: 35px;
        text-decoration: none;
    }
    .headerLinks:hover {
        color: #E3001B;
    }

    #headerLanguages {
        display: inline-block;
        cursor: pointer;
        position: relative;
    }
    .headerLinks:first-child:hover .subPagesDropdown{
        display: inline-block;
    }
    #headerLanguages:hover .languageDropdown {
        display: inline-block;
    }

    #headerLanguages i {
        color: #9A9A9A;
        width: 12px;
        height: 6px;
        margin-top: 8px;
    }

    .dropdown-arrow-animation-up {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        transform: rotate(180deg);
        transform-origin: center;
        transform-style: preserve-3D;
    }

    .dropdown-arrow-animation-down {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        transform: rotate(0deg);
        transform-origin: center;
        transform-style: preserve-3D;
    }

    .languageDropdown {
        display: none;
        position: absolute;
        top: 27px;
        left: 0;
        padding:5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        background-color:#FFF;
        z-index: 9;
    }

    .subPagesDropdown{
        position: relative;
        top: 40px;
        left: 58px;
        padding: 5px;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,.1), 0 4px 8px 0 rgba(0,0,0,.1);
        background-color: #fff;
        display: none;
     }
    .headerLinks:hover subPagesDropDown{
        display: inline-block;
    }
    .subPagesDropdown a{
        display:block;

    }

    #headerLanguages a {
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 35px;
        text-decoration: none;
    }

    #headerLanguages a:hover {
        color: #E3001B;
    }

    #headerSearch {
        display: inline-block;
        border: 1px solid #707070;
        width: 210px;
        border-radius: 13px;
        height: 25px;
        margin-left: 12px;
    }

    #headerSearch input {
        height: 23px;
        margin: 0 0 0 10px;
        border: none;
        border-right: 1px solid #707070;
        background-color: rgba(0, 0, 0, 0);
        vertical-align: top;
        width:160px;
    }

    #headerSearch input:focus {
        outline: none;
    }

    #headerSearch i {
        height: 20px;
        color: #707070;
        padding-top: 5px;
    }

    .mobileOnly {
        display: none;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    #header {
        width: 1100px;
        margin: 0 auto;
    }
    #headerContainer{
        height:80px;
    }
    #headerLogo {
        display: inline-block;
    }

    #headerLogo img {
        width: 211px;
        height: 69px;
    }

    .headerRightSide {
        float: right;
        display: inline-block;
        padding-top: 22px;
    }

    #headerLinks {
        display: inline-block;
    }

    .headerLinks {
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 35px;
        text-decoration: none;
        padding: 12px;
    }

    .headerLinks:hover {
        color: #E3001B;
    }

    #headerLanguages {
        display: inline-block;
        cursor: pointer;
        position: relative;
    }

    #headerLanguages:hover .languageDropdown {
        display: inline-block;
    }

    #headerLanguages i {
        color: #9A9A9A;
        width: 12px;
        height: 6px;
        margin-top: 8px;
    }

    .dropdown-arrow-animation-up {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        transform: rotate(180deg);
        transform-origin: center;
        transform-style: preserve-3D;
    }

    .dropdown-arrow-animation-down {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        transform: rotate(0deg);
        transform-origin: center;
        transform-style: preserve-3D;
    }

    .languageDropdown {
        display: none;
        position: absolute;
        top: 27px;
        left: 0;
        padding:5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        background-color:#FFF;
        z-index: 9;
    }

    #headerLanguages a {
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 35px;
        text-decoration: none;
    }

    #headerLanguages a:hover {
        color: #E3001B;
    }

    #headerSearch {
        display: inline-block;
        border: 1px solid #707070;
        width: 210px;
        border-radius: 13px;
        height: 25px;
        margin-left: 12px;
    }

    #headerSearch input {
        height: 23px;
        margin: 0 0 0 10px;
        border: none;
        border-right: 1px solid #707070;
        background-color: rgba(0, 0, 0, 0);
        vertical-align: top;
        width:160px;
    }

    #headerSearch input:focus {
        outline: none;
    }

    #headerSearch i {
        height: 20px;
        color: #707070;
        padding-top: 5px;
    }

    .mobileOnly {
        display: none;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #header {
        width: 100%;
        margin: 0 auto;
        padding: 10px 0px
    }
    #headerContainer{
        width: 922px;
        margin:0 auto;
    }
    #headerLogo {
        display: inline-block;
    }

    #headerLogo img {
        width: 148px;
        height: 49px;
    }

    .headerRightSide {
        float: right;
        display: inline-block;
        padding-top: 7px;
    }

    #headerLinks {
        display: inline-block;
    }

    .headerLinks {
        color: #676767;
        font-family: "Istok Regular",sans-serif;
        font-size: 13px;
        line-height: 35px;
        text-decoration: none;
        padding: 8px;
    }

    .headerLinks:hover {
        color: #E3001B;
    }

    #headerLanguages {
        display: inline-block;
        cursor: pointer;
        position: relative;
    }

    #headerLanguages:hover .languageDropdown {
        display: inline-block;
    }

    #headerLanguages i {
        color: #9A9A9A;
        width: 12px;
        height: 6px;
        margin-top: 8px;
    }

    .dropdown-arrow-animation-up {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        transform: rotate(180deg);
        transform-origin: center;
        transform-style: preserve-3D;
    }

    .dropdown-arrow-animation-down {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        transform: rotate(0deg);
        transform-origin: center;
        transform-style: preserve-3D;
    }

    .languageDropdown {
        display: none;
        position: absolute;
        top: 27px;
        left: 0;
        padding:5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        background-color:#FFF;
        z-index: 9;
    }

    #headerLanguages a {
        color: #676767;
        font-family: "Istok Regular",sans-serif;
        font-size: 12px;
        line-height: 35px;
        text-decoration: none;
    }

    #headerLanguages a:hover {
        color: #E3001B;
    }

    #headerSearch {
        display: inline-block;
        border: 1px solid #707070;
        width: 138px;
        border-radius: 13px;
        height: 25px;
        margin-left: 12px;
    }

    #headerSearch input {
        width: 100px;
        height: 23px;
        margin: 0 0 0 10px;
        border: none;
        border-right: 1px solid #707070;
        background-color: rgba(0,0,0,0);
        vertical-align: top;
    }

    #headerSearch input:focus {
        outline: none;
    }

    #headerSearch i {
        height: 20px;
        color: #707070;
        padding-top: 5px;
    }

    .mobileOnly {
        display: none;
    }


}

@media screen and (min-width: 766px) and (max-width: 1022px) {

    #headerContainer {
        margin-top: 34px;
        max-width: 100%;
        margin: 0 auto;
    }

    #header {
        width: 100%;
        margin: 0 auto;
        background-color: #FFF;
    }

    #headerMobileContainer {
        width: 666px;
        margin: 0 auto;
        padding: 10px 0px;
    }

    #burger-menu {
        cursor: pointer;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        display: inline-block;
        padding: 24px 0px;
        float: right;
    }

    #burger-menu .burgerbtn {
        font-size:32px;
        color: #9A9A9A;
    }

    #headerLogoMobile {
        display: inline-block;
    }

    #headerLogoMobile img {
        width: 238px;
        height: 78px;
    }

    #topNav {
        height: 100%;
        margin: 0 5%;
        width: 666px;
        margin: 0 auto;
    }

    .headerRightSide {
        -ms-transition: .4s;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        transition: .4s;
        display: block;
        right: -100%;
        position: fixed;
        z-index: 99999;
        width: 100%;
        background: #9A9A9A;
        height: 120px;
        top: 100px;
    }
    #headerLinks {
        display:block;
        position:relative;
        top:70px;
        width:666px;
        min-height:64px;
    }
    .headerLinks {
        color: #fff;
        font-family: "Istok Regular",sans-serif;
        font-size: 16px;
        line-height: 35px;
        text-decoration: none;
        padding: 12px 0;
    }



    #headerLanguages {
        display: inline-block;
        cursor: pointer;
        position: relative;
        float: left;
        bottom: 40px;
    }


    #headerLanguages i {
    color: #fff;
    width: 12px;
    height: 6px;
    margin-top: 8px;
    position: relative;
    top: -3px;
    }

    .dropdown-arrow-animation-up {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        transform: rotate(180deg);
        transform-origin: center;
        transform-style: preserve-3D;
    }

    .dropdown-arrow-animation-down {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        transform: rotate(0deg);
        transform-origin: center;
        transform-style: preserve-3D;
    }

    .languageDropdown {
        display: none;
        position: absolute;
        top: 27px;
        left: 0;
        padding:5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        background-color:#9A9A9A;
        z-index: 9;
    }

    #headerLanguages a {
    color: #FFF;
    font-family: "Istok Regular",sans-serif;
    font-size: 16px;
    line-height: 35px;
    text-decoration: none;
    }



    #headerSearch {
    display: inline-block;
    border: 1px solid #fff;
    width: 530px;
    border-radius: 23px;
    height: 25px;
    float: right;
    position: relative;
    bottom: 35px;
    }

    #headerSearch input {
        height: 22px;
        margin: 0 0 0 10px;
        border: none;
        border-right: 1px solid #fff;
        background-color: rgba(0,0,0,0);
        vertical-align: top;
        color: #fff;
        width: 480px;

    }

    #headerSearch input::placeholder {
        color: #FFF;
        font-size: 16px;
        vertical-align: top;
    }

    #headerSearch input:focus {
        outline: none;
    }

    #headerSearch i {
        height: 30px;
        width: 20px;
        color: #FFF;
        padding-top: 5px;
        font-size: 16px;
    }

    .desktopOnly {
        display: none;
    }

    #headerLinks a {
        display: inline-block;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #headerContainer {
        margin-top: 34px;
        max-width: 100%;
        margin: 0 auto;
    }

    #header {
        width: 100%;
        margin: 0 auto;
        background-color: #FFF;
    }

    #headerMobileContainer {
        width: 428px;
        margin: 0 auto;
        padding: 10px 0px;
    }

    #burger-menu {
        display: inline-block;
        padding: 24px 0px;
        float: right;
    }

    #burger-menu a {
        font-size: 31px;
        color: #9A9A9A;
    }

    #headerLogoMobile {
        display: inline-block;
    }

    #headerLogoMobile img {
        width: 238px;
        height: 78px;
    }

    #topNav {
        height: 100%;
        width: 428px;
        margin: 0 auto;
    }

    .headerRightSide {
        -ms-transition: .4s;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        transition: .4s;
        display: block;
        right: -100%;
        position: fixed;
        z-index: 99999;
        width: 100%;
        background: #9A9A9A;
        height: 100px;
        top:100px;
    }
    #headerLinks {
        display: block;
        position: relative;
        top: 70px;
    }
    .headerLinks {
        color: #fff;
        font-family: "Istok Regular", sans-serif;
        font-size: 14px;
        line-height: 35px;
        text-decoration: none;
        padding: 12px 0;
    }
    #headerLanguages {
        display: inline-block;
        cursor: pointer;
        position: relative;
        float: left;
        bottom: 40px;
    }


    #headerLanguages i {
        color: #FFF;
        width: 12px;
        height: 6px;
        margin-top: 8px;
    }
    .dropdown-arrow-animation-up {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        transform: rotate(180deg);
        transform-origin: center;
        transform-style: preserve-3D;
    }

    .dropdown-arrow-animation-down {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        transform: rotate(0deg);
        transform-origin: center;
        transform-style: preserve-3D;
    }

    .languageDropdown {
        display: none;
        position: absolute;
        top: 27px;
        left: 0;
        padding:5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        background-color:#9A9A9A;
        z-index: 9;
    }

    #headerLanguages a {
        color: #FFF;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
        text-decoration: none;
    }



    #headerSearch {
        display: inline-block;
        border: 1px solid #fff;
        border-radius: 13px;
        height: 25px;
        float: right;
        position: relative;
        bottom: 35px;
        width: 300px;
    }

    #headerSearch input {
        height: 24px;
        margin: 0 0 0 10px;
        border: none;
        border-right: 1px solid #fff;
        background-color: rgba(0,0,0,0);
        vertical-align: top;
        color: #fff;
        width: 250px;
    }

    #headerSearch input::placeholder {
        color: #FFF;
    }

    #headerSearch input:focus {
        outline: none;
    }

    #headerSearch i {
        color: #FFF;
        padding-top: 5px;
        font-size: 14px;
    }

    .desktopOnly {
        display: none ;
    }

    #headerLinks {
        display: block;
        position: relative;
        top:50px;
    }

    #headerLinks a {
        display: inline-block;
    }


}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #headerContainer {
        margin-top: 34px;
        max-width: 100%;
        margin: 0 auto;
    }

    #header {
        width: 100%;
        margin: 0 auto;
        background-color: #FFF;
    }

    #headerMobileContainer {
        width: 300px;
        margin: 0 auto;
        padding: 10px 0px;
    }

    #burger-menu {
        display: inline-block;
        padding: 24px 0px;
        float: right;
    }

    #burger-menu a {
        font-size: 31px;
        color: #9A9A9A;
        cursor: pointer;
    }

    #headerLogoMobile {
        display: inline-block;
    }

    #headerLogoMobile img {
        width: 238px;
        height: 78px;
    }

    #topNav {
        height: 100%;
        margin: 0 5%;
        width: 320px;
        margin: 0 auto;
    }

    .headerRightSide {
        -ms-transition: .4s;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        transition: .4s;
        display: block;
        right: -100%;
        position: fixed;
        z-index: 99999;
        width: 100%;
        background: #9A9A9A;
        height: 100px;
        top:100px;
    }

    #headerLinks {
        display: block;
        position: relative;
        top: 50px;
    }




    .headerLinks {
        color: #fff;
        font-family: "Istok Regular", sans-serif;
        font-size: 12px;
        line-height: 35px;
        text-decoration: none;
        padding: 12px 0;
    }

    .headerLinks:nth-last-child(-n+2) {
        text-align: right;
    }


    #headerLanguages {
        display: inline-block;
        cursor: pointer;
        position: relative;
        float: left;
        bottom: 35px;
    }



    #headerLanguages i {
        color: #FFF;
        width: 12px;
        height: 6px;
        margin-top: 8px;
    }

    .dropdown-arrow-animation-up {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        transform: rotate(180deg);
        transform-origin: center;
        transform-style: preserve-3D;
    }

    .dropdown-arrow-animation-down {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        transform: rotate(0deg);
        transform-origin: center;
        transform-style: preserve-3D;
    }

    .languageDropdown {
        display: none;
        position: absolute;
        top: 27px;
        left: 0;
        padding:5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        background-color:#9A9A9A;
        z-index: 9;
    }

    #headerLanguages a {
        color: #FFF;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
        text-decoration: none;
    }

    #headerSearch {
        display: inline-block;
        border: 1px solid #fff;
        width: 200px;
        border-radius: 13px;
        height: 25px;
        float: right;
        position: relative;
        bottom: 35px;
        width: 186px;
    }

    #headerSearch input {
        height: 22px;
        margin: 0 0 0 10px;
        border: none;
        border-right: 1px solid #FFF;
        background-color: rgba(0, 0, 0, 0);
        vertical-align: top;
        color: #FFF;
        width: 138px;
    }

    #headerSearch input::placeholder {
        color: #FFF;
    }

    #headerSearch input:focus {
        outline: none;
    }

    #headerSearch i {
        color: #FFF;
        padding-top: 5px;
        font-size: 14px;
    }

    .desktopOnly {
        display: none;
    }

    #headerLinks {
    display: block;
    position: relative;
    top: 50px;
    }

    #headerLinks a {
        display: inline-block;
    }


}

@media screen and (max-width: 350px) {
    #headerContainer {
        margin-top: 34px;
        max-width: 100%;
        margin: 0 auto;
    }

    #header {
        width: 100%;
        margin: 0 auto;
        background-color: #FFF;
    }

    #headerMobileContainer {
        width: 300px;
        margin: 0 auto;
        padding: 10px 0px;
    }

    #burger-menu {
        display: inline-block;
        padding: 24px 0px;
        float: right;
    }

    #burger-menu a {
        font-size: 31px;
        color: #9A9A9A;
        cursor: pointer;
    }

    #headerLogoMobile {
        display: inline-block;
    }

    #headerLogoMobile img {
        width: 238px;
        height: 78px;
    }

    #topNav {
        height: 100%;
        margin: 0 5%;
        width: 300px;
        margin: 0 auto;
    }

    .headerRightSide {
        -ms-transition: .4s;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        transition: .4s;
        display: block;
        right: -100%;
        position: fixed;
        z-index: 99999;
        width: 100%;
        background: #9A9A9A;
        height: 100px;
        top:100px;
    }

    #headerLinks {
        display: block;
        position: relative;
        top: 50px;
    }
    .headerLinks {
        color: #fff;
        font-family: "Istok Regular", sans-serif;
        font-size: 10px;
        line-height: 35px;
        text-decoration: none;
        padding: 12px 0;
    }

    .headerLinks:nth-last-child(-n+2) {
        text-align: right;
    }

    #headerLanguages {
        display: inline-block;
        cursor: pointer;
        position: relative;
        float: left;
        bottom: 35px;
    }




    #headerLanguages i {
        color: #FFF;
        width: 12px;
        height: 6px;
    }

    .dropdown-arrow-animation-up {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        transform: rotate(180deg);
        transform-origin: center;
        transform-style: preserve-3D;
    }

    .dropdown-arrow-animation-down {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        transform: rotate(0deg);
        transform-origin: center;
        transform-style: preserve-3D;
    }

    .languageDropdown {
        display: none;
        position: absolute;
        top: 27px;
        left: 0;
        padding:5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        background-color:#9A9A9A;
        z-index: 9;
    }

    #headerLanguages a {
        color: #FFF;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 35px;
        text-decoration: none;
    }

    #headerSearch {
        display: inline-block;
        border: 1px solid #fff;
        width: 200px;
        border-radius: 13px;
        height: 25px;
        float: right;
        position: relative;
        bottom: 35px;
        width: 186px;
    }

    #headerSearch input {
        height: 22px;
        margin: 0 0 0 10px;
        border: none;
        border-right: 1px solid #FFF;
        background-color: rgba(0, 0, 0, 0);
        vertical-align: top;
        color: #FFF;
        width: 138px;
    }

    #headerSearch input::placeholder {
        color: #FFF;
    }

    #headerSearch input:focus {
        outline: none;
    }

    #headerSearch i {
        color: #FFF;
        padding-top: 5px;
        font-size: 14px;
    }

    .desktopOnly {
        display: none;
    }

    #headerLinks {
    display: block;
    position: relative;
    top: 50px;
    }

    #headerLinks a {
        display: inline-block;
    }




}

@media print {
    #header {
        width: 1300px;
        margin: 0 auto;
    }

    #headerLogo {
        display: inline-block;
    }

    #headerLogo img {
        width: 238px;
        height: 78px;
    }

    .headerRightSide {
        float: right;
        display: inline-block;
        padding-top: 22px;
    }

    #headerLinks {
        display: inline-block;
    }

    .headerLinks {
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 35px;
        text-decoration: none;
        padding: 12px;
    }

    .headerLinks:hover {
        color: #E3001B;
    }

    #headerLanguages {
        display: inline-block;
        cursor: pointer;
        position: relative;
    }

    #headerLanguages:hover .languageDropdown {
        display: inline-block;
    }

    #headerLanguages i {
        color: #9A9A9A;
        width: 12px;
        height: 6px;
        margin-top: 8px;
    }

    .dropdown-arrow-animation-up {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        transform: rotate(180deg);
        transform-origin: center;
        transform-style: preserve-3D;
    }

    .dropdown-arrow-animation-down {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        transform: rotate(0deg);
        transform-origin: center;
        transform-style: preserve-3D;
    }

    .languageDropdown {
        display: none;
        position: absolute;
        top: 27px;
        left: 0;
        padding:5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        background-color:#FFF;
        z-index: 9;
    }

    #headerLanguages a {
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 35px;
        text-decoration: none;
    }

    #headerLanguages a:hover {
        color: #E3001B;
    }

    #headerSearch {
        display: inline-block;
        border: 1px solid #707070;
        width: 210px;
        border-radius: 13px;
        height: 25px;
        margin-left: 12px;
    }

    #headerSearch input {
        height: 23px;
        margin: 0 0 0 10px;
        border: none;
        border-right: 1px solid #707070;
        background-color: rgba(0, 0, 0, 0);
        vertical-align: top;
    }

    #headerSearch input:focus {
        outline: none;
    }

    #headerSearch i {
        height: 20px;
        color: #707070;
        padding-top: 5px;
    }

    .mobileOnly {
        display: none;
    }
}


.rotateTheBurger {
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.isOpenMobile {
    -ms-transition: .4s;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    transition: .4s;
    display: block;
    right: 0;
    transform-style: preserve-3D;
}

.isHiddenMobile {
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    right: -100%;
    position: fixed;
    transform-style: preserve-3D;

}

.isOpen {
    display: block;
}