@media screen and (min-width: 1400px) {
    .category-navigation {
        background: #F5F5F5;
    }

    .category-navigationContainer {
        width: 1300px;
        margin: 0 auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .category-navigation-container {
        display: inline-block;
        width: 180px;
        height: 167px;
        margin-right: 40px;
        margin-bottom: 10px;
    }

    .category-navigation-container-image {
        width: 180px;
        height: 167px;
        position: relative;
        background-size: cover;
    }

    .category-navigation-container:last-of-type {
        margin-right: 0;
    }


    .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        width: 100%;
        min-height: 44px;
        background-color: #E3001B;
        background-color: rgba(227, 0, 27, 0.7);
        position: absolute;
        overflow-wrap: break-word;
        bottom: 0;
    }

    .category-navigation-container-title h1 {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 25px;
        color: white;
        margin-left: 12px;
        margin-top: 8px;
    }

    .category-navigation-container:hover .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        min-height: 167px;
    }

    .category-navigation-container-active .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        min-height: 167px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .category-navigation {
        background: #F5F5F5;
    }

    .category-navigationContainer {
        width: 1100px;
        margin: 0 auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }


    .category-navigation-container {
        display: inline-block;
        width: 180px;
        height: 167px;
        margin-right: 40px;
        margin-bottom:10px;
    }

    .category-navigation-container-image {
        width: 180px;
        height: 167px;
        position: relative;
        background-size: cover;
    }

    .category-navigation-container:last-of-type {
        margin-right: 0;
    }



    .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        width: 100%;
        min-height: 44px;
        background-color: #E3001B;
        background-color: rgba(227, 0, 27, 0.7);
        position: absolute;
        bottom: 0;
        overflow-wrap: break-word;
    }

    .category-navigation-container-title h1 {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 25px;
        color: white;
        margin-left: 12px;
        margin-top: 8px;
    }

    .category-navigation-container:hover .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        min-height: 167px;
    }

    .category-navigation-container-active .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        height: 167px;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .category-navigation {
        background: #F5F5F5;
        width: 100%;
    }

    .category-navigationContainer {
        width: 922px;
        margin: 0 auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .category-navigation-container {
        display: inline-block;
        width: 180px;
        height: 167px;
        margin-bottom: 10px;
    }

    .category-navigation-container-image {
        width: 180px;
        height: 167px;
        position: relative;
        background-size: cover;
    }

    .category-navigation-container:last-of-type {
        margin-right: 0;
    }

    .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        width: 100%;
        min-height: 44px;
        background-color: #E3001B;
        background-color: rgba(227, 0, 27, 0.7);
        position: absolute;
        bottom: 0;
        overflow-wrap: break-word;
    }

    .category-navigation-container-title h1 {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 25px;
        color: white;
        margin-left: 12px;
        margin-top: 8px;
    }

    .category-navigation-container:hover .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        min-height: 167px;
    }

    .category-navigation-container-active .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        height: 167px;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .category-navigation {
        background: #F5F5F5;
        width: 100%;
    }

    .category-navigationContainer {
        width: 666px;
        margin: 0 auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    /* apply on other mobile queries*/
    .category-navigation-container {
        display: inline-block;
        width: 215px;
        height: 200px;
        margin-right: 6px;
        padding-bottom: 8px;
    }

    /* apply on other mobile queries*/
    .category-navigation-container:nth-child(3n+3) {
        margin-right: 0;
    }

    /* apply on other mobile queries*/
    .category-navigation-container-image {
        width: 215px;
        height: 200px;
        position: relative;
        background-size: cover;
    }

    .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        width: 100%;
        min-height: 44px;
        background-color: #E3001B;
        background-color: rgba(227, 0, 27, 0.7);
        position: absolute;
        bottom: 0;
        overflow: hidden;
        overflow-wrap: break-word;
    }

    .category-navigation-container-title h1 {
        font-family: "Istok Regular", sans-serif;
        font-size: 19px;
        line-height: 25px;
        color: white;
        margin-left: 12px;
        margin-top: 8px;
    }

    .category-navigation-container:hover .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        min-height: 200px;
    }

    .category-navigation-container-active .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        height: 200px;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .category-navigation {
        background: #F5F5F5;
        width: 100%;
    }

    .category-navigationContainer {
        width: 428px;
        margin: 0 auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .category-navigation-container {
        display: inline-block;
        width:200px;
        height: 215px;
        padding-right:24px;
    }
    .category-navigation-container:nth-child(even){
        padding-right:0px;
    }

    .category-navigation-container:last-of-type {
        margin-right: 0;
    }

    .category-navigation-container-image {
        width: 100%;
        height: 200px;
        position: relative;
        background-size: cover;
    }

    .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        width: 100%;
        min-height: 44px;
        background-color: #E3001B;
        background-color: rgba(227, 0, 27, 0.7);
        position: absolute;
        bottom: 0;
        overflow: hidden;
        overflow-wrap: break-word;
    }

    .category-navigation-container-title h1 {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 25px;
        color: white;
        padding: 10px 5px;
    }

    .category-navigation-container-active .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        height: 200px;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .category-navigation {
        background: #F5F5F5;
        width: 100%;
    }

    .category-navigationContainer {
        width: 300px;
        margin: 0 auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .category-navigation-container {
        display: inline-block;
        width: 145px;
        padding-right:4px;
        padding-bottom:5px;
    }
    .category-navigation-container:nth-child(even){
        padding-right:0px;
    }

    .category-navigation-container:last-of-type {
        margin-right: 0;
    }

    .category-navigation-container-image {
        width: 100%;
        height: 135px;
        position: relative;
        background-size: contain;
        background-position: center;
    }

    .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        width: 100%;
        height: 44px;
        background-color: #E3001B;
        background-color: rgba(227, 0, 27, 0.7);
        position: absolute;
        bottom: 0;
        overflow: hidden;
        overflow-wrap: break-word;
    }

    .category-navigation-container-title h1 {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 1;
        color: #fff;
        vertical-align: middle;
        padding:10px 5px;
    }

    .category-navigation-container-active .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        height: 135px;
    }
}

@media screen and (max-width: 350px) {
    .category-navigation {
        background: #F5F5F5;
        width: 100%;
    }

    .category-navigationContainer {
        width: 300px;
        margin: 0 auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .category-navigation-container {
        display: inline-block;
        width: 145px;
        padding-right:4px;
        padding-bottom:5px;
    }
    .category-navigation-container:nth-child(even){
        padding-right:0px;
    }

    .category-navigation-container:last-of-type {
        margin-right: 0;
    }

    .category-navigation-container-image {
        width: 100%;
        height: 135px;
        position: relative;
        background-size: contain;
        background-position: center;

    }

    .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        width: 100%;
        height: 44px;
        background-color: #E3001B;
        background-color: rgba(227, 0, 27, 0.7);
        position: absolute;
        bottom: 0;
        overflow: hidden;
        overflow-wrap: break-word;
    }

    .category-navigation-container-title h1 {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 1;
        color: #fff;
        vertical-align: middle;
        padding: 10px 5px;
    }

    .category-navigation-container-active .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        height: 135px;
    }
}

@media print {
    .category-navigation {
        background: #F5F5F5;
    }

    .category-navigationContainer {
        width: 1300px;
        margin: 0 auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .category-navigation-container {
        display: inline-block;
        width: 215px;
        height: 200px;
        margin-right: 52px;
    }

    .category-navigation-container:last-of-type {
        margin-right: 0;
    }

    .category-navigation-container-image {
        width: 215px;
        height: 200px;
        position: relative;
        background-size: cover;
    }

    .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        width: 100%;
        height: 44px;
        background-color: #E3001B;
        background-color: rgba(227, 0, 27, 0.7);
        position: absolute;
        bottom: 0;
    }

    .category-navigation-container-title h1 {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 25px;
        color: white;
        margin-left: 12px;
        margin-top: 8px;
    }

    .category-navigation-container:hover .category-navigation-container-title {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        min-height: 200px;
    }
}