/*Remove the x icon from type="search" <input /> elements*/
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}
.searchButton {
    border: none;
    background: transparent;
    padding:0;
}

.searchButton:hover{
    cursor: pointer;
}
@media screen and (min-width: 1400px){
    .search-container{
        width:1300px;
        margin: 0 auto;
        padding: 30px 0px;
    }
    .search-input{
        display: block;
        border: 1px solid #707070;
        width: 1300px;
        border-radius: 38px;
        height: 67px;
        margin-bottom: 30px;
    }
    .search-input input{
        height: 66px;
        margin: 0 0 0 40px;
        border: none;
        border-right: 1px solid #707070;
        background-color: rgba(0,0,0,0);
        vertical-align: top;
        width: 1190px;
        font-size:20px;
    }

    .search-input input::placeholder{
        color:black;
    }
    .search-input input:focus{
        outline: none;;
    }

    .search-input i{
        color: #707070;
        font-size: 32px;
        font-weight: 400;
        padding-left: 8px;
        padding-top: 18px;
    }

    .search-single-result{
        display:inline-block;
        width: 630px;
        padding-right:20px;
        padding-bottom:30px;
        font-family: "Istok Bold",sans-serif;
        vertical-align: middle;
    }

    .search-single-result:nth-child(odd){
        padding-right:0px;
    }

    .search-single-result a{
        text-decoration: none;
        color: #707070;

    }

    .search-single-title{
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        display:block;
        color: #E3001B;
        font-size:20px;
        line-height: 26px;
        padding-bottom:12px;

    }


    .search-single-content{
        padding-top:20px;
        font-size:16px;
        line-height: 22px;
        height: 150px;
        overflow: hidden;
        border-top: 1px solid #9A9A9A;

    }

}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .search-container{
        width:1100px;
        margin: 0 auto;
        padding: 30px 0px;
    }
    .search-input{
        display: block;
        border: 1px solid #707070;
        border-radius: 38px;
        height: 67px;
        margin-bottom: 30px;
    }
    .search-input input{
        height: 66px;
        margin: 0 0 0 40px;
        border: none;
        border-right: 1px solid #707070;
        background-color: rgba(0,0,0,0);
        vertical-align: top;
        width: 990px;
        font-size:20px;
    }

    .search-input input::placeholder{
        columns: #9A9A9A;
    }
    .search-input input:focus{
        outline: none;;
    }

    .search-input i{
        color: #707070;
        font-size: 32px;
        font-weight: 400;
        padding-left: 8px;
        padding-top: 18px;
    }

    .search-single-result{
        display:inline-block;
        width: 530px;
        padding-right:20px;
        padding-bottom:30px;
        font-family: "Istok Bold",sans-serif;
        vertical-align: middle;
    }

    .search-single-result:nth-child(odd){
        padding-right:0px;
    }

    .search-single-result a{
        text-decoration: none;
        color: #707070;

    }

    .search-single-title{
        border-bottom: 1px solid #9A9A9A;
        display:block;
        color: #E3001B;
        font-size:20px;
        line-height: 26px;
        padding-bottom:12px;
    }
    .search-single-content{
        padding-top:20px;
        font-size:16px;
        line-height: 22px;
        height: 150px;
        overflow: hidden;

    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .search-container{
        width:922px;
        margin: 0 auto;
        padding: 30px 0px;
    }
    .search-input{
        display: block;
        border: 1px solid #707070;
        border-radius: 38px;
        height: 67px;
        margin-bottom:30px;
    }
    .search-input input{
        height: 66px;
        margin: 0 0 0 40px;
        border: none;
        border-right: 1px solid #707070;
        background-color: rgba(0,0,0,0);
        vertical-align: top;
        width: 812px;
        font-size:20px;
    }

    .search-input input::placeholder{
        color:#9A9A9A;
    }
    .search-input input:focus{
        outline: none;;
    }

    .search-input i{
        color: #707070;
        font-size: 32px;
        font-weight: 400;
        padding-left: 8px;
        padding-top: 18px;
    }

    .search-single-result{
        display:inline-block;
        width: 450px;
        padding-right:18px;
        padding-bottom:30px;
        font-family: "Istok Bold",sans-serif;
        vertical-align: middle;
    }
    .search-single-result:nth-child(odd){
        padding-right:0px;
    }
    .search-single-result a{
        text-decoration: none;
        color: #707070;
    }

    .search-single-title{
        border-bottom: 1px solid #9A9A9A;
        display:block;
        color: #E3001B;
        font-size:20px;
        line-height: 26px;
        padding-bottom:12px;
    }
    .search-single-content{
        padding-top:20px;
        font-size:16px;
        line-height: 22px;
        height: 150px;
        overflow: hidden;

    }
}
@media screen and (min-width: 766px) and (max-width: 1022px) {
    .search-container{
        width:666px;
        margin: 0 auto;
        padding: 30px 0px;
    }
    .search-input{
        display: block;
        border: 1px solid #707070;
        border-radius: 38px;
        height: 67px;
        margin-bottom: 30px;
    }
    .search-input input{
        height: 66px;
        margin: 0 0 0 40px;
        border: none;
        border-right: 1px solid #707070;
        background-color: rgba(0,0,0,0);
        vertical-align: top;
        width: 550px;
        font-size:20px;
    }

    .search-input input::placeholder{
        color:#9A9A9A;
    }
    .search-input input:focus{
        outline: none;;
    }

    .search-input i{
        color: #707070;
        font-size: 32px;
        font-weight: 400;
        padding-left: 8px;
        padding-top: 18px;
    }

    .search-single-result{
        display:inline-block;
        width: 320px;
        padding-right:20px;
        padding-bottom:30px;
        font-family: "Istok Bold",sans-serif;
        vertical-align: middle;
    }

    .search-single-result:nth-child(odd){
        padding-right:0px;
    }

    .search-single-result a{
        text-decoration: none;
        color: #707070;

    }

    .search-single-title{
        border-bottom: 1px solid #9A9A9A;
        display:block;
        color: #E3001B;
        font-size:20px;
        line-height: 26px;
        padding-bottom:12px;
    }
    .search-single-content{
        padding-top:20px;
        font-size:16px;
        line-height: 22px;
        height: 150px;
        overflow: hidden;

    }
}
/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .search-container{
        width:428px;
        margin: 0 auto;
        padding: 30px 0px;
    }
    .search-input{
        display: block;
        border: 1px solid #707070;
        border-radius: 38px;
        height: 50px;
        margin-bottom: 30px;
    }
    .search-input input{
        height: 49px;
        margin: 0 0 0 20px;
        border: none;
        border-right: 1px solid #707070;
        background-color: rgba(0,0,0,0);
        vertical-align: top;
        width: 360px;
        font-size:16px;
    }

    .search-input input::placeholder{
        color:#9A9A9A;
    }
    .search-input input:focus{
        outline: none;;
    }

    .search-input i{
        color: #707070;
        font-size: 20px;
        font-weight: 400;
        padding-top: 16px;
        padding-left: 4px;
    }

    .search-single-result{
        display:block;
        padding-right:20px;
        padding-bottom:30px;
        font-family: "Istok Bold",sans-serif;
    }

    .search-single-result:nth-child(odd){
        padding-right:0px;
    }

    .search-single-result a{
        text-decoration: none;
        color: #707070;
    }

    .search-single-title{
        border-bottom: 1px solid #9A9A9A;
        display:block;
        color: #E3001B;
        font-size:18px;
        line-height: 26px;
        padding-bottom:12px;
    }
    .search-single-content{
        padding-top:20px;
        font-size:16px;
        line-height: 22px;
        height: 150px;
        overflow: hidden;

    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .search-container{
        width:300px;
        margin: 0 auto;
        padding: 30px 0px;
    }
    .search-input{
        display: block;
        border: 1px solid #707070;
        border-radius: 38px;
        height: 50px;
        margin-bottom: 30px;
    }
    .search-input input{
        height: 49px;
        margin: 0 0 0 20px;
        border: none;
        border-right: 1px solid #707070;
        background-color: rgba(0,0,0,0);
        vertical-align: top;
        width: 230px;
        font-size:16px;
    }

    .search-input input::placeholder{
        color:#9A9A9A;
    }
    .search-input input:focus{
        outline: none;;
    }

    .search-input i{
        color: #707070;
        font-size: 20px;
        font-weight: 400;
        padding-top: 16px;
        padding-left: 4px;
    }

    .search-single-result{
        display:block;
        padding-right:20px;
        padding-bottom:30px;
        font-family: "Istok Bold",sans-serif;
    }

    .search-single-result:nth-child(odd){
        padding-right:0px;
    }

    .search-single-result a{
        text-decoration: none;
        color: #707070;
    }

    .search-single-title{
        border-bottom: 1px solid #9A9A9A;
        display:block;
        color: #E3001B;
        font-size:18px;
        line-height: 26px;
        padding-bottom:12px;
    }
    .search-single-content{
        padding-top:20px;
        font-size:16px;
        line-height: 22px;
        height: 150px;
        overflow: hidden;

    }
}

@media screen and (max-width: 350px){
    .search-container{
        width:300px;
        margin: 0 auto;
        padding: 30px 0px;
    }
    .search-input{
        display: block;
        border: 1px solid #707070;
        border-radius: 38px;
        height: 50px;
        margin-bottom:30px;
    }
    .search-input input{
        height: 49px;
        margin: 0 0 0 20px;
        border: none;
        border-right: 1px solid #707070;
        background-color: rgba(0,0,0,0);
        vertical-align: top;
        width: 230px;
        font-size:16px;
    }

    .search-input input::placeholder{
        color:#9A9A9A;
    }
    .search-input input:focus{
        outline: none;;
    }

    .search-input i{
        color: #707070;
        font-size: 16px;
        font-weight: 400;
        padding-top: 16px;
        padding-left: 10px;

    }

    .search-single-result{
        display:block;
        padding-right:20px;
        padding-bottom:30px;
        font-family: "Istok Bold",sans-serif;
    }

    .search-single-result:nth-child(odd){
        padding-right:0px;
    }

    .search-single-result a{
        text-decoration: none;
        color: #707070;
    }

    .search-single-title{
        border-bottom: 1px solid #9A9A9A;
        display:block;
        color: #E3001B;
        font-size:18px;
        line-height: 26px;
        padding-bottom:12px;
    }
    .search-single-content{
        padding-top:20px;
        font-size:16px;
        line-height: 22px;
        height: 150px;
        overflow: hidden;

    }
}

@media print {
    .search-container{
        width:1300px;
        margin: 0 auto;
        padding: 30px 0px;
    }
    .search-input{
        display: block;
        border: 1px solid #707070;
        width: 1300px;
        border-radius: 38px;
        height: 67px;

    }
    .search-input input{
        height: 66px;
        margin: 0 0 0 40px;
        border: none;
        border-right: 1px solid #707070;
        background-color: rgba(0,0,0,0);
        vertical-align: top;
        width: 1190px;
        font-size:20px;
    }

    .search-input input::placeholder{
        color:black;
    }
    .search-input input:focus{
        outline: none;;
    }

    .search-input i{
        color: #707070;
        font-size: 32px;
        font-weight: 400;
        padding-left: 8px;
        padding-top: 18px;
    }

    .search-single-result{
        display:inline-block;
        width: 630px;
        padding-right:20px;
        padding-bottom:30px;
        font-family: "Istok Bold",sans-serif;
        vertical-align: middle;
    }

    .search-single-result:nth-child(odd){
        padding-right:0px;
    }

    .search-single-result a{
        text-decoration: none;
        color: #707070;

    }

    .search-single-title{
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        display:block;
        color: #E3001B;
        font-size:20px;
        line-height: 26px;
        padding-bottom:12px;

    }


    .search-single-content{
        padding-top:20px;
        font-size:16px;
        line-height: 22px;
        height: 150px;
        overflow: hidden;
        border-top: 1px solid #9A9A9A;

    }
}


