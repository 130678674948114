@media screen and (min-width: 1400px) {
    .home-news-container {
        background-color: #F5F5F5;
    }

    .home-news-headline {
        font-family: "Istok Regular", sans-serif;
        font-size: 30px;
        line-height: 35px;
        letter-spacing: 7px;
        color: #E3001B;
        text-align: center;
        text-transform: uppercase;
        padding-top: 38px;
        padding-bottom: 38px;
        border-bottom: 1px solid #9A9A9A;
    }

    .home-news-row-inner-container {
        width: 1300px;
        margin: 0 auto;
    }

    .home-news-page-container-single {
        width: 320px;
        display: inline-block;
        margin-right: 82px;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 82px;
        border-right: 1px solid #9A9A9A;
        vertical-align: top;
    }

    .home-news-last-element-on-row {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
    }

    .home-news-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 22px;
        color: #676767;
    }

    .home-news-page-container-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #e3001b;
        min-height: 60px;
    }

    .home-news-page-container-image {
        max-width: 320px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #9A9A9A;
    }

    .home-news-page-container-description {
        padding-top: 20px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        min-height: 90px;
    }

    .home-news-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        max-width: 144px;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }

    .home-news-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .home-news-container {
        background-color: #F5F5F5;
    }

    .home-news-headline {
        font-family: "Istok Regular", sans-serif;
        font-size: 30px;
        line-height: 35px;
        letter-spacing: 7px;
        color: #E3001B;
        text-align: center;
        text-transform: uppercase;
        padding-top: 38px;
        padding-bottom: 38px;
        border-bottom: 1px solid #9A9A9A;
    }

    .home-news-row-inner-container {
        width: 1100px;
        margin: 0 auto;
    }

    .home-news-page-container-single {
        width: 336px;
        display: inline-block;
        margin-right: 15px;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 15px;
        border-right: 1px solid #9A9A9A;
        vertical-align: top;
    }

    .home-news-last-element-on-row {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
    }

    .home-news-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
    }

    .home-news-page-container-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #e3001b;
        min-height: 60px;
    }

    .home-news-page-container-image {
        max-width: 336px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #9A9A9A;
    }

    .home-news-page-container-description {
        padding-top: 20px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        min-height: 90px;
    }

    .home-news-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        max-width: 144px;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }

    .home-news-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .home-news-container {
        background-color: #F5F5F5;
    }

    .home-news-headline {
        font-family: "Istok Regular", sans-serif;
        font-size: 30px;
        line-height: 35px;
        letter-spacing: 7px;
        color: #E3001B;
        text-align: center;
        text-transform: uppercase;
        padding-top: 38px;
        padding-bottom: 38px;
        border-bottom: 1px solid #9A9A9A;
    }

    .home-news-row-inner-container {
        width: 922px;
        margin: 0 auto;
    }

    .home-news-page-container-single {
        width: 246px;
        display: inline-block;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 33px;
        border-right: 1px solid #9A9A9A;
        vertical-align: top;
        margin-right: 33px;
    }

    .home-news-last-element-on-row {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
    }

    .home-news-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 15px;
        line-height: 22px;
        color: #676767;
    }

    .home-news-page-container-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #e3001b;
        min-height: 60px;
    }

    .home-news-page-container-image {
        max-width: 246px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #9A9A9A;
    }

    .home-news-page-container-description {
        padding-top: 20px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        min-height: 90px;
    }

    .home-news-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        max-width: 144px;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }

    .home-news-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .home-news-container {
        background-color: #F5F5F5;
    }

    .home-news-headline {
        font-family: "Istok Regular", sans-serif;
        font-size: 30px;
        line-height: 35px;
        letter-spacing: 7px;
        color: #E3001B;
        text-align: center;
        text-transform: uppercase;
        padding-top: 38px;
        padding-bottom: 38px;
        border-bottom: 1px solid #9A9A9A;
    }

    .home-news-row-inner-container {
        width: 666px;
        margin: 0 auto;
    }

    .home-news-page-container-single {
        width: 100%;
        display: block;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-news-last-element-on-row {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
    }

    .home-news-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding: 10px 0px;
    }

    .home-news-page-container-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 24px;
        line-height: 30px;
        color: #e3001b;
        min-height: 60px;
        border-bottom: 1px solid;
        padding-bottom: 20px;
    }

    .home-news-page-container-image {
        max-width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #9A9A9A;
        display: none;
    }

    .home-news-page-container-description {
        padding-top: 20px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        min-height: 90px;
    }

    .home-news-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        max-width: 100%;
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 40px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }

    .home-news-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .home-news-container {
        background-color: #F5F5F5;
    }

    .home-news-headline {
        font-family: "Istok Regular", sans-serif;
        font-size: 30px;
        line-height: 52px;
        letter-spacing: 7px;
        color: #E3001B;
        text-align: center;
        text-transform: uppercase;
        padding-top: 38px;
        padding-bottom: 38px;
        border-bottom: 1px solid #9A9A9A;
    }

    .home-news-row-inner-container {
        width: 428px;
        margin: 0 auto;
    }

    .home-news-page-container-single {
        display: block;
        padding-top: 40px;
        padding-bottom: 40px;
        margin: 0 auto;
    }



    .home-news-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding: 10px 0px;
    }

    .home-news-page-container-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 24px;
        line-height: 30px;
        color: #e3001b;
        min-height: 60px;
        border-bottom: 1px solid;
        padding-bottom: 20px;
    }

    .home-news-page-container-image {
        max-width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #9A9A9A;
        display: none;
    }

    .home-news-page-container-description {
        padding-top: 20px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        min-height: 90px;
    }

    .home-news-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        max-width: 100%;
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 40px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }

    .home-news-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .home-news-container {
        background-color: #F5F5F5;
    }

    .home-news-headline {
        font-family: "Istok Regular", sans-serif;
        font-size: 22px;
        line-height: 35px;
        letter-spacing: 7px;
        color: #E3001B;
        text-align: center;
        text-transform: uppercase;
        padding-top: 38px;
        padding-bottom: 38px;
        border-bottom: 1px solid #9A9A9A;
    }

    .home-news-row-inner-container {
        margin: 0 auto;
        width: 300px;

    }

    .home-news-page-container-single {
        display: block;
        padding-top: 40px;
        padding-bottom: 40px;
        margin: 0 auto;
    }



    .home-news-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding: 10px 0px;
    }

    .home-news-page-container-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 30px;
        color: #e3001b;
        min-height: 60px;
    }

    .home-news-page-container-image {
        width: 100%;
        height: 180px;
        padding-top: 16px;
        padding-bottom: 20px;
    }

    .home-news-page-container-description {
        padding-top: 20px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        min-height: 90px;
    }

    .home-news-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        -ms-transition: .4s;
        -o-transition: .4s;
        transition: .4s;
        border: 1px solid #e3001b;
        color: #e3001b;
        max-width: 100%;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }

    .home-news-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }
}

@media screen and (max-width: 350px) {
    .home-news-container {
        background-color: #F5F5F5;
    }

    .home-news-headline {
        font-family: "Istok Regular", sans-serif;
        font-size: 22px;
        line-height: 35px;
        letter-spacing: 7px;
        color: #E3001B;
        text-align: center;
        text-transform: uppercase;
        padding-top: 38px;
        padding-bottom: 38px;
        border-bottom: 1px solid #9A9A9A;
    }

    .home-news-row-inner-container {
        width: 300px;
        margin: 0 auto;
    }

    .home-news-page-container-single {
        display: block;
        padding-top: 40px;
        padding-bottom: 40px;
        margin: 0 auto;
    }

    .home-news-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        padding: 10px 0px;
    }

    .home-news-page-container-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 30px;
        color: #e3001b;
        min-height: 60px;
    }

    .home-news-page-container-image {
        width: 100%;
        height: 180px;
        padding-top: 20px;
        padding-bottom: 30px;
        border-bottom: 1px solid #e3001b;
    }

    .home-news-page-container-single::after {
        border-bottom: 1px solid;
        width: 100%;
        content: '';
    }

    .home-news-page-container-description {
        padding-top: 20px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        min-height: 90px;
    }

    .home-news-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        max-width: 100%;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }

    .home-news-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }

}

@media print {
    .home-news-container {
        background-color: #F5F5F5;
    }

    .home-news-headline {
        font-family: "Istok Regular", sans-serif;
        font-size: 30px;
        line-height: 35px;
        letter-spacing: 7px;
        color: #E3001B;
        text-align: center;
        text-transform: uppercase;
        padding-top: 38px;
        padding-bottom: 38px;
        border-bottom: 1px solid #9A9A9A;
    }

    .home-news-row-inner-container {
        width: 1300px;
        margin: 0 auto;
    }

    .home-news-page-container-single {
        width: 320px;
        display: inline-block;
        margin-right: 82px;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 82px;
        border-right: 1px solid #9A9A9A;
        vertical-align: top;
    }

    .home-news-last-element-on-row {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
    }

    .home-news-page-container-single-date {
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 22px;
        color: #676767;
    }

    .home-news-page-container-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #e3001b;
        min-height: 60px;
    }

    .home-news-page-container-image {
        max-width: 320px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #9A9A9A;
    }

    .home-news-page-container-description {
        padding-top: 20px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        min-height: 90px;
    }

    .home-news-more-button {
        display: block;
        margin-top: 20px;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        max-width: 144px;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        padding: 13px;
        text-decoration: none;
    }

    .home-news-more-button:hover {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        background-color: #E3001B;
        color: white;
    }
}