@media screen and (min-width: 1400px){
    .contact-title {
        font-family: "Istok Regular", sans-serif;
        font-size: 18px;
        line-height: 25px;
        color: #E3001B;
        padding-top:16px;
    }


    .rightSidebarContact-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarContact-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarContact-emailContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarContact-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarContact-person-name {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #E3001B;
        padding-top: 13px;
        padding-bottom: 10px;
    }

    .rightSidebarContact-download-link {
        border-bottom: 1px solid #9A9A9A;
    }

    .rightSidebarContact-download-link i {
        color: #9A9A9A;
        padding-right: 13px;
    }

    .rightSidebarContact-download-link a {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .contact-title {
        font-family: "Istok Regular", sans-serif;
        font-size: 18px;
        line-height: 25px;
        color: #E3001B;
        padding-top:16px;
    }


    .rightSidebarContact-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarContact-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarContact-emailContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarContact-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarContact-person-name {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #E3001B;
        padding-top: 13px;
        padding-bottom: 10px;
    }

    .rightSidebarContact-download-link {
        border-bottom: 1px solid #9A9A9A;
    }

    .rightSidebarContact-download-link i {
        color: #9A9A9A;
        padding-right: 13px;
    }

    .rightSidebarContact-download-link a {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .contact-title {
        font-family: "Istok Regular", sans-serif;
        font-size: 18px;
        line-height: 25px;
        color: #E3001B;
        padding-top:16px;
    }


    .rightSidebarContact-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarContact-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarContact-emailContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarContact-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarContact-person-name {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #E3001B;
        padding-top: 13px;
        padding-bottom: 10px;
    }

    .rightSidebarContact-download-link {
        border-bottom: 1px solid #9A9A9A;
    }

    .rightSidebarContact-download-link i {
        color: #9A9A9A;
        padding-right: 13px;
    }

    .rightSidebarContact-download-link a {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .contact-title {
        font-family: "Istok Regular", sans-serif;
        font-size: 18px;
        line-height: 25px;
        color: #E3001B;
        padding-top:16px;
    }


    .rightSidebarContact-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarContact-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarContact-emailContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarContact-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarContact-person-name {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #E3001B;
        padding-top: 13px;
        padding-bottom: 10px;
    }

    .rightSidebarContact-download-link {
        border-bottom: 1px solid #9A9A9A;
    }

    .rightSidebarContact-download-link i {
        color: #9A9A9A;
        padding-right: 13px;
    }

    .rightSidebarContact-download-link a {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }
    .rightSidebarContact-download-link:last-child{
        border: none;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .contact-title {
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 25px;
        color: #E3001B;
    }


    .rightSidebarContact-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarContact-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarContact-emailContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarContact-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarContact-person-name {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #E3001B;
        padding-top: 13px;
        padding-bottom: 10px;
    }

    .rightSidebarContact-download-link {
        border-bottom: 1px solid #9A9A9A;
    }

    .rightSidebarContact-download-link i {
        color: #9A9A9A;
        padding-right: 13px;
    }

    .rightSidebarContact-download-link a {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }
    .rightSidebarContact-download-link:last-child{
        border: none;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .contact-title {
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 25px;
        color: #E3001B;
    }


    .rightSidebarContact-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarContact-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarContact-emailContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarContact-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarContact-person-name {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #E3001B;
        padding-top: 13px;
        padding-bottom: 10px;
    }

    .rightSidebarContact-download-link {
        border-bottom: 1px solid #9A9A9A;
    }

    .rightSidebarContact-download-link i {
        color: #9A9A9A;
        padding-right: 13px;
    }

    .rightSidebarContact-download-link a {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }
    .rightSidebarContact-download-link:last-child{
        border: none;
    }
}

@media screen and (max-width: 350px){
    .contact-title {
        font-family: "Istok Regular", sans-serif;
        font-size: 18px;
        line-height: 25px;
        color: #E3001B;
    }


    .rightSidebarContact-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarContact-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarContact-emailContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarContact-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarContact-person-name {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #E3001B;
        padding-top: 13px;
        padding-bottom: 10px;
    }

    .rightSidebarContact-download-link {
        border-bottom: 1px solid #9A9A9A;
    }

    .rightSidebarContact-download-link i {
        color: #9A9A9A;
        padding-right: 13px;
    }

    .rightSidebarContact-download-link a {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }
    .rightSidebarContact-download-link:last-child{
        border: none;
    }
}

@media print {
    .contact-title {
        font-family: "Istok Regular", sans-serif;
        font-size: 18px;
        line-height: 25px;
        color: #E3001B;
    }


    .rightSidebarContact-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarContact-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarContact-emailContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarContact-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarContact-person-name {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #E3001B;
        padding-top: 13px;
        padding-bottom: 10px;
    }

    .rightSidebarContact-download-link {
        border-bottom: 1px solid #9A9A9A;
    }

    .rightSidebarContact-download-link i {
        color: #9A9A9A;
        padding-right: 13px;
    }

    .rightSidebarContact-download-link a {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }
}