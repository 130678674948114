@media screen and (min-width: 1400px){
    #sliderContainer {
        width: 100%;
        height: 600px;
    }

    #sliderContainer .owl-stage-outer {
        height: 600px;
    }

    .owl-stage {
        height: 600px;
    }
    #sliderContainer .owl-item {
        height: 600px;
    }
    #sliderContainer .singleSlide {
        height: 600px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
    }

    #sliderContainer .singleSlide .singleSlideTitle {
        font-family: "Istok Regular", sans-serif;
        font-size: 65px;
        line-height: 35px;
        letter-spacing: 18px;
    }

    #sliderContainer .singleSlide .singleSlideSubTitle {
        font-family: "Istok Bold", sans-serif;
        font-size: 30px;
        line-height: 35px;
    }

    /* slider - dots */
    #sliderContainer .owl-dots {
        width: 200px;
        position: absolute;
        bottom: 20px;
        text-align: center;
        left: 50%;
        margin-left: -100px;
    }
    #sliderContainer .owl-dot {
        width: 12px;
        height: 12px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 0 10px;
    }
    #sliderContainer .owl-dot:first-of-type {
        margin-left: 0;
    }
    #sliderContainer .owl-dot:last-of-type {
        margin-right: 0;
    }
    #sliderContainer .owl-dots .active {
        background-color: #fff;
    }

    /* slider texts */
    #sliderOuterTexts{
        display: none;
    }
    #sliderContainer h1 {
        color: #fff;
        font-size: 46px;
        padding-top: 12%;
        line-height: 52px;
        text-align: center;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
    }
    #sliderContainer h1 span {
        font-family: "Frutiger 67 Condensed", sans-serif !important;
    }

    /* slider - bottom blue line */
    #homePageSliderBottomLine {
        width: calc(100vw - (100vw - 1300px) / 2);
        height: 3px;
        background-color: #31417a;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #sliderContainer {
        width: 100%;
        height: 600px;
    }

    #sliderContainer .owl-stage-outer {
        height: 600px;
    }

    .owl-stage {
        height: 600px;
    }
    #sliderContainer .owl-item {
        height: 600px;
    }
    #sliderContainer .singleSlide {
        height: 600px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
        text-transform:uppercase;
    }

    #sliderContainer .singleSlide .singleSlideTitle {
        font-family: "Istok Regular", sans-serif;
        font-size: 65px;
        line-height: 35px;
        letter-spacing: 18px;
    }

    #sliderContainer .singleSlide .singleSlideSubTitle {
        font-family: "Istok Bold", sans-serif;
        font-size: 28px;
        line-height: 32px;
    }

    /* slider - dots */
    #sliderContainer .owl-dots {
        width: 200px;
        position: absolute;
        bottom: 20px;
        text-align: center;
        left: 50%;
        margin-left: -100px;
    }
    #sliderContainer .owl-dot {
        width: 12px;
        height: 12px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 0 10px;
    }
    #sliderContainer .owl-dot:first-of-type {
        margin-left: 0;
    }
    #sliderContainer .owl-dot:last-of-type {
        margin-right: 0;
    }
    #sliderContainer .owl-dots .active {
        background-color: #fff;
    }

    /* slider texts */
    #sliderOuterTexts{
        display: none;
    }
    #sliderContainer h1 {
        color: #fff;
        font-size: 46px;
        padding-top: 12%;
        line-height: 52px;
        text-align: center;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
    }
    #sliderContainer h1 span {
        font-family: "Frutiger 67 Condensed", sans-serif !important;
    }

    /* slider - bottom blue line */
    #homePageSliderBottomLine {
        width: calc(100vw - (100vw - 1100px) / 2);
        height: 3px;
        background-color: #31417a;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #sliderContainer {
        width: 100%;
        height: 600px;
    }

    #sliderContainer .owl-stage-outer {
        height: 600px;
        width:100%
    }

    .owl-stage {
        height: 600px;
    }
    #sliderContainer .owl-item {
        height: 600px;
    }
    #sliderContainer .singleSlide {
        height: 600px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
        text-transform:uppercase;

    }

    #sliderContainer .singleSlide .singleSlideTitle {
        font-family: "Istok Regular", sans-serif;
        font-size: 65px;
        line-height: 35px;
        letter-spacing: 18px;
    }

    #sliderContainer .singleSlide .singleSlideSubTitle {
        font-family: "Istok Bold", sans-serif;
        font-size: 26px;
        line-height: 30px;
    }

    /* slider - dots */
    #sliderContainer .owl-dots {
        width: 200px;
        position: absolute;
        bottom: 20px;
        text-align: center;
        left: 50%;
        margin-left: -100px;
    }
    #sliderContainer .owl-dot {
        width: 12px;
        height: 12px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 0 10px;
    }
    #sliderContainer .owl-dot:first-of-type {
        margin-left: 0;
    }
    #sliderContainer .owl-dot:last-of-type {
        margin-right: 0;
    }
    #sliderContainer .owl-dots .active {
        background-color: #fff;
    }

    /* slider texts */
    #sliderOuterTexts{
        display: none;
    }
    #sliderContainer h1 {
        color: #fff;
        font-size: 46px;
        padding-top: 12%;
        line-height: 52px;
        text-align: center;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
    }
    #sliderContainer h1 span {
        font-family: "Frutiger 67 Condensed", sans-serif !important;
    }

    /* slider - bottom blue line */
    #homePageSliderBottomLine {
        width: calc(100vw - (100vw - 922px) / 2);
        height: 3px;
        background-color: #31417a;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #sliderContainer {
        width: 100%;
        height: 480px;
    }

    #sliderContainer .owl-stage-outer {
        height: 480px;
    }

    .owl-stage {
        height: 480px;
    }
    #sliderContainer .owl-item {
        height: 480px;
    }
    #sliderContainer .singleSlide {
        height: 480px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
        text-transform:uppercase;

    }

    #sliderContainer .singleSlide .singleSlideTitle {
        font-family: "Istok Regular", sans-serif;
        font-size: 65px;
        line-height: 35px;
        letter-spacing: 18px;
    }

    #sliderContainer .singleSlide .singleSlideSubTitle {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 25px;
    }

    /* slider - dots */
    #sliderContainer .owl-dots {
        width: 200px;
        position: absolute;
        bottom: 20px;
        text-align: center;
        left: 50%;
        margin-left: -100px;
    }
    #sliderContainer .owl-dot {
        width: 12px;
        height: 12px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 0 10px;
    }
    #sliderContainer .owl-dot:first-of-type {
        margin-left: 0;
    }
    #sliderContainer .owl-dot:last-of-type {
        margin-right: 0;
    }
    #sliderContainer .owl-dots .active {
        background-color: #fff;
    }

    /* slider texts */
    #sliderOuterTexts{
        display: none;
    }
    #sliderContainer h1 {
        color: #fff;
        font-size: 46px;
        padding-top: 12%;
        line-height: 52px;
        text-align: center;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
    }
    #sliderContainer h1 span {
        font-family: "Frutiger 67 Condensed", sans-serif !important;
    }

    /* slider - bottom blue line */
    #homePageSliderBottomLine {
        width: calc(100vw - (100vw - 666px) / 2);
        height: 3px;
        background-color: #31417a;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #sliderContainer {
        width: 100%;
        height: 480px;
    }

    #sliderContainer .owl-stage-outer {
        height: 480px;
    }

    .owl-stage {
        height: 480px;
    }
    #sliderContainer .owl-item {
        height: 480px;
    }
    #sliderContainer .singleSlide {
        height: 480px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
        text-transform:uppercase;

    }

    #sliderContainer .singleSlide .singleSlideTitle {
        font-family: "Istok Regular", sans-serif;
        font-size: 45px;
        line-height: 55px;
        letter-spacing: 18px;
    }

    #sliderContainer .singleSlide .singleSlideSubTitle {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 25px;
    }

    /* slider - dots */
    #sliderContainer .owl-dots {
        width: 200px;
        position: absolute;
        bottom: 20px;
        text-align: center;
        left: 50%;
        margin-left: -100px;
    }
    #sliderContainer .owl-dot {
        width: 12px;
        height: 12px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 0 10px;
    }
    #sliderContainer .owl-dot:first-of-type {
        margin-left: 0;
    }
    #sliderContainer .owl-dot:last-of-type {
        margin-right: 0;
    }
    #sliderContainer .owl-dots .active {
        background-color: #fff;
    }

    /* slider texts */
    #sliderOuterTexts{
        display: none;
    }
    #sliderContainer h1 {
        color: #fff;
        font-size: 46px;
        padding-top: 12%;
        line-height: 52px;
        text-align: center;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
    }
    #sliderContainer h1 span {
        font-family: "Frutiger 67 Condensed", sans-serif !important;
    }

    /* slider - bottom blue line */
    #homePageSliderBottomLine {
        width: calc(100vw - (100vw - 420px) / 2);
        height: 3px;
        background-color: #31417a;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #sliderContainer {
        width: 100%;
        height: 480px;
    }

    #sliderContainer .owl-stage-outer {
        height: 480px;
    }

    .owl-stage {
        height: 480px;
    }
    #sliderContainer .owl-item {
        height: 480px;
    }
    #sliderContainer .singleSlide {
        height: 480px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
        text-transform:uppercase;

    }

    #sliderContainer .singleSlide .singleSlideTitle {
        font-family: "Istok Regular",sans-serif;
        font-size: 45px;
        line-height: 55px;
        letter-spacing: 12.15px
    }

    #sliderContainer .singleSlide .singleSlideSubTitle {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 25px;
    }

    /* slider - dots */
    #sliderContainer .owl-dots {
        width: 200px;
        position: absolute;
        bottom: 20px;
        text-align: center;
        left: 50%;
        margin-left: -100px;
    }
    #sliderContainer .owl-dot {
        width: 12px;
        height: 12px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 0 10px;
    }
    #sliderContainer .owl-dot:first-of-type {
        margin-left: 0;
    }
    #sliderContainer .owl-dot:last-of-type {
        margin-right: 0;
    }
    #sliderContainer .owl-dots .active {
        background-color: #fff;
    }

    /* slider texts */
    #sliderOuterTexts{
        display: none;
    }
    #sliderContainer h1 {
        color: #fff;
        font-size: 46px;
        padding-top: 12%;
        line-height: 52px;
        text-align: center;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
    }
    #sliderContainer h1 span {
        font-family: "Frutiger 67 Condensed", sans-serif !important;
    }

    /* slider - bottom blue line */
    #homePageSliderBottomLine {
        width: calc(100vw - (100vw - 320px) / 2);
        height: 3px;
        background-color: #31417a;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

@media screen and (max-width: 350px){
    #sliderContainer {
        width: 100%;
        height: 480px;
    }

    #sliderContainer .owl-stage-outer {
        height: 480px;
        width: 100%;
    }

    .owl-stage {
        height: 480px;
    }
    #sliderContainer .owl-item {
        height: 480px;
    }
    #sliderContainer .singleSlide {
        height: 480px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
        text-transform:uppercase;

    }

    #sliderContainer .singleSlide .singleSlideTitle {
        font-family: "Istok Regular", sans-serif;
        font-size: 45px;
        line-height: 55px;
        letter-spacing: 18px;
    }

    #sliderContainer .singleSlide .singleSlideSubTitle {
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 25px;
    }

    /* slider - dots */
    #sliderContainer .owl-dots {
        width: 200px;
        position: absolute;
        bottom: 20px;
        text-align: center;
        left: 50%;
        margin-left: -100px;
    }
    #sliderContainer .owl-dot {
        width: 12px;
        height: 12px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 0 10px;
    }
    #sliderContainer .owl-dot:first-of-type {
        margin-left: 0;
    }
    #sliderContainer .owl-dot:last-of-type {
        margin-right: 0;
    }
    #sliderContainer .owl-dots .active {
        background-color: #fff;
    }

    /* slider texts */
    #sliderOuterTexts{
        display: none;
    }
    #sliderContainer h1 {
        color: #fff;
        font-size: 46px;
        padding-top: 12%;
        line-height: 52px;
        text-align: center;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
    }
    #sliderContainer h1 span {
        font-family: "Frutiger 67 Condensed", sans-serif !important;
    }

    /* slider - bottom blue line */
    #homePageSliderBottomLine {
        width: calc(100vw - (100vw - 280px) / 2);
        height: 3px;
        background-color: #31417a;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

@media print {
    #sliderContainer {
        width: 100%;
        height: 600px;
    }

    #sliderContainer .owl-stage-outer {
        height: 600px;
    }

    .owl-stage {
        height: 600px;
    }
    #sliderContainer .owl-item {
        height: 600px;
    }
    #sliderContainer .singleSlide {
        height: 600px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
        text-transform:uppercase;

    }

    #sliderContainer .singleSlide .singleSlideTitle {
        font-family: "Istok Regular", sans-serif;
        font-size: 65px;
        line-height: 35px;
        letter-spacing: 18px;
    }

    #sliderContainer .singleSlide .singleSlideSubTitle {
        font-family: "Istok Bold", sans-serif;
        font-size: 20px;
        line-height: 25px;
    }

    /* slider - dots */
    #sliderContainer .owl-dots {
        width: 200px;
        position: absolute;
        bottom: 20px;
        text-align: center;
        left: 50%;
        margin-left: -100px;
    }
    #sliderContainer .owl-dot {
        width: 12px;
        height: 12px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 0 10px;
    }
    #sliderContainer .owl-dot:first-of-type {
        margin-left: 0;
    }
    #sliderContainer .owl-dot:last-of-type {
        margin-right: 0;
    }
    #sliderContainer .owl-dots .active {
        background-color: #fff;
    }

    /* slider texts */
    #sliderOuterTexts{
        display: none;
    }
    #sliderContainer h1 {
        color: #fff;
        font-size: 46px;
        padding-top: 12%;
        line-height: 52px;
        text-align: center;
        font-family: "Frutiger 47 Light Condensed", sans-serif;
    }
    #sliderContainer h1 span {
        font-family: "Frutiger 67 Condensed", sans-serif !important;
    }

    /* slider - bottom blue line */
    #homePageSliderBottomLine {
        width: calc(100vw - (100vw - 1300px) / 2);
        height: 3px;
        background-color: #31417a;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}