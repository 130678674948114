/* Non  Media query related styles */
.more-button:hover {
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    background-color: #E3001B;
    color: white;
}

@media screen and (min-width: 1400px) {
    .richText-content-container{
        width:1300px;
        margin: 0 auto;
        padding:50px 0px;
        min-height:640px;
    }
    .richText-content-container p{
        margin: 10px 0px;
        font-family: "Istok Regular", sans-serif;
    }

    #categories {
        border-top: 5px solid #E3001B;
    }

    #categoriesContainer {
        width: 1300px;
        margin: 42px auto 0;
    }

    .home-category-container {
        width: 366px;
        height: 320px;
        display: inline-block;
        margin-right: 97px;
        margin-bottom: 40px;
        vertical-align: top;

    }

    .home-category-container:nth-child(3n+3) {
        margin-right: 0;
    }

    .home-category-image-container {
        width: 366px;
        height: 200px;
        position: relative;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .home-category-title-container {
        width: 100%;
        min-height: 44px;
        background-color: #E3001B;
        position: absolute;
        bottom: 0;
    }

    .home-category-title-container h1 {
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: white;
        margin-left: 12px;
        margin-top: 8px;
    }

    .home-category-description {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .more-button {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        width: 144px;
        height: 44px;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        padding: 13px 52px;
        text-decoration: none;
    }


}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .richText-content-container{
        width:1100px;
        margin: 0 auto;
        padding:50px 0px;
        min-height:640px;
    }
    #categories {
        border-top: 5px solid #E3001B;
    }

    #categoriesContainer {
        width: 1100px;
        margin: 42px auto 0;
        box-sizing: border-box;
    }

    .home-category-container {
        width: 300px;
        height: 320px;
        display: inline-block;
        margin-right: 96px;
        margin-bottom: 40px;
        vertical-align: top;
    }

    .home-category-container:nth-child(3n+3) {
        margin-right: 0;
    }

    .home-category-image-container {
        width: 300px;
        height: 200px;
        position: relative;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .home-category-title-container {
        width: 100%;
        min-height: 44px;
        background-color: #E3001B;
        position: absolute;
        bottom: 0;
    }

    .home-category-title-container h1 {
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: white;
        margin-left: 12px;
        margin-top: 8px;
    }

    .home-category-description {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .more-button {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        width: 144px;
        height: 44px;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        padding: 13px 52px;
        text-decoration: none;
    }


}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .richText-content-container{
        width:922px;
        margin: 0 auto;
        padding:50px 0px;
        min-height:640px;
    }
    /* Normal Menu - last Media query before mobile menu (burger menu)  */
    #categories {
        border-top: 5px solid #E3001B;

    }

    #categoriesContainer {
        width: 922px;
        margin: 42px auto 0;
        box-sizing: border-box;
    }

    .home-category-container {
        width: 260px;
        height: 320px;
        display: inline-block;
        margin-right: 66px;
        margin-bottom: 40px;
        vertical-align: top;
    }

    .home-category-container:nth-child(3n+3) {
        margin-right: 0;
    }

    .home-category-image-container {
        width: 260px;
        height: 200px;
        position: relative;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .home-category-title-container {
        width: 100%;
        min-height: 60px;
        background-color: #E3001B;
        position: absolute;
        bottom: 0;
        overflow: hidden;
    }

    .home-category-title-container h1 {
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: white;
        margin-left: 12px;
        margin-top: 8px;
    }

    .home-category-description {
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        color: #676767;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .more-button {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        width: 144px;
        height: 44px;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        padding: 13px 52px;
        text-decoration: none;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .richText-content-container{
        width:666px;
        margin: 0 auto;
        padding:50px 0px;
    }
    /* Burger menu from here on now  */
    #categories {
        border-top: 5px solid #E3001B;

    }

    #categoriesContainer {
        width: 666px;
        margin: 96px auto 0;
        box-sizing: border-box;
    }

    .home-category-container {
        width: 320px;
        display: inline-block;
        margin-right: 22px;
        margin-bottom: 60px;
        border-bottom: 1px solid #e3001b;
        vertical-align: top;
    }
    .home-category-container:nth-child(2n+2){
        margin-right:0px;
    }


    .home-category-image-container {
        width: 317px;
        height: 200px;
        position: relative;
        background-size: contain;
        background-repeat: no-repeat;

    }

    .home-category-title-container {
        width: 100%;
        min-height: 44px;
        position: absolute;
        top: -44px;
    }

    .home-category-title-container h1 {
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #e3001b;
    }

    .home-category-description {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        margin-top: 20px;
        margin-bottom: 20px;
        height:88px;
    }

    .more-button {
        -webkit-transition: .4s;
        -moz-transition: .4s;
        -ms-transition: .4s;
        -o-transition: .4s;
        transition: .4s;
        color: #e3001b;
        height: 44px;
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 34px;
        text-align: center;
        text-decoration: none;
        float: right;
        padding: 5px 2px 0px 2px;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {

    .richText-content-container{
        width:428px;
        margin: 0 auto;
        padding:20px 0px;
    }

    #categories {
        border-top: 5px solid #E3001B;

    }

    #categoriesContainer {
        box-sizing: border-box;
        width: 428px;
        margin: 96px auto 0;
    }

    .home-category-container {
        width: 210px;
        display: inline-block;
        margin-bottom: 66px;
        border-bottom: 1px solid #e3001b

    }
    .home-category-container:nth-child(even){
        float:right;
    }



    .home-category-image-container {
        width: 100%;
        min-height: 88px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;

    }

    .home-category-title-container {
        width: 100%;
        min-height: 44px;
        position: absolute;
        top: -66px;
    }

    .home-category-title-container h1 {
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #e3001b;
        margin-top: 8px;
    }

    .home-category-description {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        margin-top: 20px;
        margin-bottom: 20px;
        height:88px;
    }

    .more-button {
        -webkit-transition: .4s;
        -moz-transition: .4s;
        -ms-transition: .4s;
        -o-transition: .4s;
        transition: .4s;
        color: #9A9A9A;
        height: 44px;
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 34px;
        text-align: center;
        text-decoration: none;
        float: right;
        padding: 5px 2px 0px 2px;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .richText-content-container{
        width:300px;
        margin: 0 auto;
        padding:20px 0px;
    }
    #categories {
        border-top: 5px solid #E3001B;

    }

    #categoriesContainer {
        width: 100%;
        margin: 96px auto 0;
        box-sizing: border-box;
    }

    .home-category-container {
        width: 300px;
        display: block;
        margin-right: 8px;
        border-bottom: 1px solid #e3001b;
        margin: 10px auto;
        margin-bottom: 72px;

    }



    .home-category-image-container {
        width: 100%;
        height: 180px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;

    }

    .home-category-title-container {
        width: 100%;
        min-height: 44px;
        position: absolute;
        top: -66px;
    }

    .home-category-title-container h1 {
        font-family: "Istok Regular", sans-serif;
        font-size: 22px;
        line-height: 30px;
        color: #e3001b;
        margin-top: 10px;
    }

    .home-category-description {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 6px;
    }

    .more-button {
        -webkit-transition: .4s;
        -moz-transition: .4s;
        -ms-transition: .4s;
        -o-transition: .4s;
        transition: .4s;
        color: #9A9A9A;
        height: 44px;
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 34px;
        text-align: center;
        text-decoration: none;
        padding: 5px 2px 0px 2px;
        position: relative;
        top: 0px;
        left: 80%;
    }
}

@media screen and (max-width: 350px) {
    .richText-content-container{
        width:300px;
        margin: 0 auto;
        padding:20px 0px;
    }
    /* Width should be 300 on the containers , due to Iphone being 320px  */
    #categories {
        border-top: 5px solid #E3001B;

    }

    #categoriesContainer {
        width: 100%;
        margin: 96px auto 0;
        box-sizing: border-box;
    }

    .home-category-container {
        width: 300px;
        display: block;
        margin-right: 8px;
        border-bottom: 1px solid #e3001b;
        margin: 0 auto;
        margin-bottom: 96px;

    }
    .home-category-image-container {
        width: 100%;
        height: 150px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .home-category-title-container {
        width: 100%;
        position: relative;
        top: -60px;
    }

    .home-category-title-container h1 {
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 30px;
        color: #e3001b;
    }

    .home-category-description {
        font-family: "Istok Regular",sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 6px;
    }

    .more-button {
        -webkit-transition: .4s;
        -moz-transition: .4s;
        -ms-transition: .4s;
        -o-transition: .4s;
        transition: .4s;
        color: #9A9A9A;
        height: 44px;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-align: center;
        text-decoration: none;
        padding: 5px 2px 0px 2px;
        position: relative;
        top: 0px;
        left: 85%;
    }
}

@media print {
    #categories {
        border-top: 5px solid #E3001B;
    }

    #categoriesContainer {
        width: 1300px;
        margin: 42px auto 0;
    }

    .home-category-container {
        width: 366px;
        height: 320px;
        display: inline-block;
        margin-right: 97px;
        margin-bottom: 40px;
        vertical-align: top;
    }

    .home-category-container:nth-child(3n+3) {
        margin-right: 0;
    }

    .home-category-image-container {
        width: 366px;
        height: 200px;
        position: relative;
        background-size: contain;
    }

    .home-category-title-container {
        width: 100%;
        min-height: 44px;
        background-color: #E3001B;
        position: absolute;
        bottom: 0;
    }

    .home-category-title-container h1 {
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: white;
        margin-left: 12px;
        margin-top: 8px;
    }

    .home-category-description {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .more-button {
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #E3001B;
        color: #E3001B;
        width: 144px;
        height: 44px;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        padding: 13px 52px;
        text-decoration: none;
    }
}
.isOpenMobile{
    -ms-transition: .4s;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    transition: .4s;
    display: block;
    right: 0;
    transform-style: preserve-3D;
}
.isHiddenMobile {
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -ms-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    right: -100%;
    position: fixed;
    transform-style: preserve-3D;
}