@media screen and (min-width: 1400px) {
    .navigation {
        background-color: #F5F5F5;
    }

    #navigationContainer {
        width: 1300px;
        margin: 0 auto;
        padding:20px 0px;
    }

    .navigationLink {
        display: inline-block;
        text-decoration: none;
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding-bottom:5px;

    }

    .navigationLink:last-of-type {
        padding-right: 0;
    }

    .navigationLink:hover {
        color: #E3001B;
    }

    .navigationLink-active {
        color: #E3001B;
    }

    #backToTopButton{
        display:none;
        clear: both;
        position: fixed;
        bottom: 60px;
        right: 30px;
        opacity: 0.6;
        text-align: center;
        font-size: 28px;
        border: 1px solid #e3001b;
        border-radius: 68px;
        background-color: #f5f5f5;
        padding: 10px 18px;;
        color: #e3001b;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition:   0.4s;
    }

}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .navigation {
        background-color: #F5F5F5;
    }

    #navigationContainer {
        width: 1100px;
        margin: 0 auto;
        padding:20px 0px;
    }

    .navigationLink {
        display: inline-block;
        text-decoration: none;
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 15px;
        line-height: 22px;
        vertical-align: top;
        padding-bottom:5px;
    }

    .navigationLink:last-of-type {
        padding-right: 0;
    }

    .navigationLink:hover {
        color: #E3001B;
    }

    .navigationLink-active {
        color: #E3001B;
    }

    #backToTopButton{
        display:none;
        clear: both;
        position: fixed;
        bottom: 60px;
        right: 30px;
        opacity: .6;
        text-align: center;
        font-size: 28px;
        border: 1px solid #e3001b;
        border-radius: 68px;
        background-color: #f5f5f5;
        padding: 10px 18px; ;
        color: #e3001b;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        -ms-transition: .4s;
        -o-transition: .4s;
        transition: .4s;
    }

}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .navigation {
        background-color: #F5F5F5;
        padding-bottom:10px;
    }

    #navigationContainer {
        width: 922px;
        margin: 0 auto;
        padding:20px 0px;
    }

    .navigationLink {
        display: inline-block;
        text-decoration: none;
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        vertical-align: top;
    }

    .navigationLink:last-of-type {
        padding-right: 0;
        text-align: right;
    }

    .navigationLink:hover {
        color: #E3001B;
    }

    .navigationLink-active {
        color: #E3001B;
    }

    #backToTopButton{
        display: none;
        clear: both;
        position: fixed;
        bottom: 60px;
        right: 30px;
        opacity: .6;
        text-align: center;
        font-size: 28px;
        border: 1px solid #e3001b;
        border-radius: 68px;
        background-color: #f5f5f5;
        padding: 10px 18px;;
        color: #e3001b;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        -ms-transition: .4s;
        -o-transition: .4s;
        transition: .4s;
    }

}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .navigation {
        -ms-transition: .4s;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        transition: .4s;
        display: block;
        right: -100%;
        position: fixed;
        background: #F5F5F5;
        z-index: 2;
        width: 100%;
        top: 220px;
        height: 500px;

    }

    #navigationContainer {
        width: 666px;
        margin: 0 auto;
        padding: 10px 0px;
    }

    .navigationLink {
        display: block;
        text-decoration: none;
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 33px;
        padding: 12px 0px;
        border-bottom: 1px solid #E3001B;
    }

    .navigationLink-active {
        color: #E3001B;
    }

    #backToTopButton{
        display: none;
        position: fixed;
        bottom: 60px;
        right: 30px;
        opacity: .6;
        text-align: center;
        font-size: 28px;
        border: 1px solid #e3001b;
        border-radius: 68px;
        background-color: #f5f5f5;
        padding: 10px 18px;;
        color: #e3001b;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        -ms-transition: .4s;
        -o-transition: .4s;
        transition: .4s;
    }

}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .navigation {
        -ms-transition: .4s;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        transition: .4s;
        display: block;
        right: -100%;
        position: fixed;
        background: #F5F5F5;
        z-index: 2;
        width: 100%;
        top: 200px;
        height: 500px;
    }

    #navigationContainer {
        width: 420px;
        margin: 0 auto;
        padding: 10px 0px;
    }

    .navigationLink {
        display: block;
        text-decoration: none;
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 33px;
        padding: 12px 0;
        border-bottom: 1px solid #E3001B;
    }

    #backToTopButton{
        display: none;
        position: fixed;
        bottom: 60px;
        right: 30px;
        opacity: .6;
        text-align: center;
        font-size: 28px;
        border: 1px solid #e3001b;
        border-radius: 68px;
        background-color: #f5f5f5;
        padding: 10px 18px;;
        color: #e3001b;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        -ms-transition: .4s;
        -o-transition: .4s;
        z-index: 9;
        transition: .4s;
    }

}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .navigation {
        -ms-transition: .4s;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        transition: .4s;
        display: block;
        right: -100%;
        position: fixed;
        background: #f5f5f5;
        z-index: 2;
        width: 100%;
        top: 200px;
        padding: 10px 0px;
    }

    #navigationContainer {
        width: 300px;
        margin: 0 auto;
    }

    .navigationLink {
        display: block;
        text-decoration: none;
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 33px;
        padding: 10px 0px;
        border-bottom: 1px solid #E3001B;
    }

    #backToTopButton{
        display: none;
        position: fixed;
        bottom: 60px;
        right: 30px;
        opacity: 0.6;
        text-align: center;
        font-size: 28px;
        border: 1px solid #e3001b;
        border-radius: 68px;
        background-color: #f5f5f5;
        padding: 10px 18px;;
        color: #e3001b;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition:   0.4s;
    }

}

@media screen and (max-width: 350px) {
    .navigation {
        -ms-transition: .4s;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        transition: .4s;
        display: block;
        right: -100%;
        position: fixed;
        background: #f5f5f5;
        z-index: 2;
        width: 100%;
        top: 200px;
        height: 500px;
    }

    #navigationContainer {
        width: 300px;
        margin: 0 auto;
    }

    .navigationLink {
        display: block;
        text-decoration: none;
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 14px;
        line-height: 33px;
        padding: 12px 0px;
        border-bottom: 1px solid #E3001B;
    }

    #backToTopButton{
        display:none;
        position: fixed;
        bottom: 60px;
        right: 30px;
        opacity: 0.6;
        text-align: center;
        font-size: 28px;
        border: 1px solid #e3001b;
        border-radius: 68px;
        background-color: #f5f5f5;
        padding: 10px 18px;;
        color: #e3001b;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition:   0.4s;
    }

}

@media print {
    .navigation {
        background-color: #F5F5F5;
        height: 60px;
    }

    #navigationContainer {
        width: 1300px;
        margin: 0 auto;
    }

    .navigationLink {
        display: inline-block;
        text-decoration: none;
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding-top: 20px;
        padding-right: 55px;
    }

    .navigationLink:last-of-type {
        padding-right: 0;
    }

    .navigationLink:hover {
        color: #E3001B;
    }

    #backToTopButton{
        display: none;
        position: fixed;
        display:none;
        bottom: 60px;
        right: 30px;
        opacity: 0.6;
        text-align: center;
        font-size: 28px;
        border: 1px solid #e3001b;
        border-radius: 68px;
        background-color: #f5f5f5;
        padding: 10px 18px;;
        color: #e3001b;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        transition:   0.4s;
    }
}
.navigation{
    clear:both;
}

.stickyNavigation{
    position: fixed;
    z-index: 10;
    top:0;
    width:100%;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    transition:   0.4s;
}

.showBackToTop{
    display:block;
}