@media screen and (min-width: 1400px){
    #map {
        width: 100%;
        height: 400px;
        background-color: white;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #map {
        width: 100%;
        height: 400px;
        background-color: white;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #map {
        width: 100%;
        height: 400px;
        background-color: white;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #map {
        width: 100%;
        height: 400px;
        background-color: white;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #map {
        width: 100%;
        height: 400px;
        background-color: white;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #map {
        width: 100%;
        height: 400px;
        background-color: white;
    }
}

@media screen and (max-width: 350px){
    #map {
        width: 100%;
        height: 400px;
        background-color: white;
    }
}

@media print {
    #map {
        width: 100%;
        height: 400px;
        background-color: white;
    }
}