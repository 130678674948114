@media screen and (min-width: 1400px){
    .main-contact-page {
        width: 1300px;
        margin: 40px auto 0;
    }

    .main-contact-container {
        display: inline-block;
        width: 600px;
        margin-right: 96px;
        margin-bottom: 40px;
        border-bottom: 1px solid #E3001B;
        padding-bottom: 20px;
    }

    .main-contact-page-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #676767;
        margin-bottom: 20px;
    }

    .title-in-red {
        color: #E3001B;
        font-size: 20px;
    }

    .main-contact-page-left,
    .main-contact-page-right {
        width: 298px; /* was 45% show peter*/
        display: inline-block;
    }

    .main-contact-page-left {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 33px;
        color: #676767;
    }

    .main-contact-page-right {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 34px;
        color: #676767;
    }

    .main-contact-page-right-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .main-contact-page-right-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .main-contact-page-right-faxContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .main-contact-page-right-faxContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }
    .main-contact-container:nth-child(2n+2){
        margin-right:0 ;
    }

    
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .main-contact-page {
        width: 1100px;
        margin: 40px auto 0;
    }

    .main-contact-container {
        display: inline-block;
        width: 500px;
        margin-right: 96px;
        margin-bottom: 40px;
        border-bottom: 1px solid #E3001B;
        padding-bottom: 20px;
    }

    .main-contact-page-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #676767;
        margin-bottom: 20px;
    }

    .title-in-red {
        color: #E3001B;
        font-size: 20px;
    }

    .main-contact-page-left,
    .main-contact-page-right {
        width: 248px;
        display: inline-block;
    }

    .main-contact-page-left {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 33px;
        color: #676767;
    }

    .main-contact-page-right {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 34px;
        color: #676767;
    }

    .main-contact-page-right-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .main-contact-page-right-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .main-contact-page-right-faxContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .main-contact-page-right-faxContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }
    .main-contact-container:nth-child(2n+2){
        margin-right:0 ;
    }

    
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .main-contact-page {
        width: 922px;
        margin: 40px auto 0;
    }

    .main-contact-container {
        display: inline-block;
        width: 440px;
        margin-right: 30px;
        margin-bottom: 40px;
        border-bottom: 1px solid #E3001B;
        padding-bottom: 20px;
    }
    .main-contact-container:nth-child(2n+2){
        margin-right:0 ;
    }

    .main-contact-page-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #676767;
        margin-bottom: 20px;
    }

    .title-in-red {
        color: #E3001B;
        font-size: 20px;
    }

    .main-contact-page-left,
    .main-contact-page-right {
        width:100%;
        display:block;

    }

    .main-contact-page-left {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 33px;
        color: #676767;
    }

    .main-contact-page-right {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 34px;
        color: #676767;
    }

    .main-contact-page-right-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 14px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }
    .main-contact-page-right .email-link  {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 14px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .main-contact-page-right-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .main-contact-page-right-faxContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 14px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .main-contact-page-right-faxContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    
    .main-contact-page-left-person-address-1{
        display:inline-block;
    }
    .main-contact-page-left-person-address-2{
        display:inline-block;
    }
    .main-contact-page-left-person-address-3{
        display:inline-block;
    }
    
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .main-contact-page {
        width: 666px;
        margin: 40px auto 0;
    }

    .main-contact-container {
        display: inline-block;
        width: 316px;
        margin-right: 30px;
        margin-bottom: 40px;
        border-bottom: 1px solid #E3001B;
        padding-bottom: 20px;
    }
    .main-contact-container:nth-child(2n+2){
        margin-right:0 ;
    }

    .main-contact-page-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #676767;
        margin-bottom: 20px;
    }

    .title-in-red {
        color: #E3001B;
        font-size: 20px;
    }

    .main-contact-page-left,
    

    .main-contact-page-left {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 33px;
        color: #676767;
        display:block;
        width:100%
    }
    

    .main-contact-page-right {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 34px;
        color: #676767;
        width:100%;
        display:block;
    }
    
    

    .main-contact-page-right-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 14px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .main-contact-page-right-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .main-contact-page-right-faxContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 14px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .main-contact-page-right-faxContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .main-contact-page-right .email-link  {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 14px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .main-contact-page-right-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }
    /* Utility use of classes to display the contact information inline */
    .main-contact-page-left-person-address-1{
        display:inline-block;
    }
    .main-contact-page-left-person-address-2{
        display:inline-block;
    }
    .main-contact-page-left-person-address-3{
        display:inline-block;
    }
    .main-contact-page-right-faxContainer{
        display:block;
    }
    .main-contact-page-right-phoneContainer{
        display:block;
    }
    .main-contact-page-right-emailContainer{
        display:block;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .main-contact-page {
        width: 428px;
        margin: 40px auto 0;
    }

    .main-contact-container {
        display: block;
        width: 100%;
        margin-right: 10px;
        margin-bottom: 40px;
        border-bottom: 1px solid #E3001B;
        padding-bottom: 20px;
    }
    .main-contact-container:nth-child(2n+2){
        margin-right:0 ;
    }

    .main-contact-page-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #676767;
        margin-bottom: 20px;
    }

    .title-in-red {
        color: #E3001B;
        font-size: 20px;
    }

    .main-contact-page-left,
    .main-contact-page-right {
        display: inline-block;
    }

    .main-contact-page-left {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 33px;
        color: #676767;
        width:100%;
    }

    .main-contact-page-right {
        font-family: "Istok Bold", sans-serif;
        font-size: 14px;
        line-height: 34px;
        color: #676767;
        width:100%;
    }

    .main-contact-page-right-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 14px;
        line-height: 34px;
        text-decoration: none;
        padding-left:10px;
    }

    .main-contact-page-right-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .main-contact-page-right-faxContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 14px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .main-contact-page-right-faxContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .main-contact-page-right .email-link  {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 14px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .main-contact-page-right-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }
    .main-contact-page-left-person-address-1{
        display:inline-block;
    }
    .main-contact-page-left-person-address-2{
        display:inline-block;
    }
    .main-contact-page-left-person-address-3{
        display:inline-block;
    }
    .main-contact-page-right-faxContainer{
        display:block;
    }
    .main-contact-page-right-phoneContainer{
        display:block;
    }
    .main-contact-page-right-emailContainer{
        display:block;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .main-contact-page {
        width: 300px;
        margin: 40px auto 0;
    }

    .main-contact-container {
        display: block;
        width: 100%;
        margin-right: 10px;
        margin-bottom: 40px;
        border-bottom: 1px solid #E3001B;
        padding-bottom: 20px;
    }
    .main-contact-container:nth-child(2n+2){
        margin-right:0 ;
    }

    .main-contact-page-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #676767;
        margin-bottom: 20px;
    }

    .title-in-red {
        color: #E3001B;
        font-size: 20px;
    }

    .main-contact-page-left,
    .main-contact-page-right {
        display: inline-block;
    }

    .main-contact-page-left {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 33px;
        color: #676767;
        width:100%;
    }

    .main-contact-page-right {
        font-family: "Istok Bold", sans-serif;
        font-size: 14px;
        line-height: 34px;
        color: #676767;
        width:100%;
    }

    .main-contact-page-right-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 14px;
        line-height: 34px;
        text-decoration: none;
        padding-left:10px;
    }

    .main-contact-page-right-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .main-contact-page-right-faxContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 14px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .main-contact-page-right-faxContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .main-contact-page-right .email-link  {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 14px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .main-contact-page-right-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }
    .main-contact-page-left-person-address-1{
        display:inline-block;
    }
    .main-contact-page-left-person-address-2{
        display:inline-block;
    }
    .main-contact-page-left-person-address-3{
        display:inline-block;
    }
    .main-contact-page-right-faxContainer{
        display:block;
    }
    .main-contact-page-right-phoneContainer{
        display:block;
    }
    .main-contact-page-right-emailContainer{
        display:block;
    }
}

@media screen and (max-width: 350px){
    .main-contact-page {
        width: 300px;
        margin: 40px auto 0;
    }

    .main-contact-container {
        display: block;
        width: 100%;
        margin-right: 10px;
        margin-bottom: 40px;
        border-bottom: 1px solid #E3001B;
        padding-bottom: 20px;
    }
    .main-contact-container:nth-child(2n+2){
        margin-right:0 ;
    }

    .main-contact-page-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #676767;
        margin-bottom: 20px;
    }

    .title-in-red {
        color: #E3001B;
        font-size: 20px;
    }

    .main-contact-page-left,
    .main-contact-page-right {
        display: inline-block;
    }

    .main-contact-page-left {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 33px;
        color: #676767;
        width:100%;
    }

    .main-contact-page-right {
        font-family: "Istok Bold", sans-serif;
        font-size: 14px;
        line-height: 34px;
        color: #676767;
        width:100%;
    }

    .main-contact-page-right-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 14px;
        line-height: 34px;
        text-decoration: none;
        padding-left:10px;
    }

    .main-contact-page-right-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .main-contact-page-right-faxContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 14px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .main-contact-page-right-faxContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .main-contact-page-right .email-link  {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 14px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .main-contact-page-right-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }
    .main-contact-page-left-person-address-1{
        display:inline-block;
    }
    .main-contact-page-left-person-address-2{
        display:inline-block;
    }
    .main-contact-page-left-person-address-3{
        display:inline-block;
    }
    .main-contact-page-right-faxContainer{
        display:block;
    }
    .main-contact-page-right-phoneContainer{
        display:block;
    }
    .main-contact-page-right-emailContainer{
        display:block;
    }
}

@media print {
    .main-contact-page {
        width: 1300px;
        margin: 40px auto 0;
    }

    .main-contact-container {
        display: inline-block;
        width: 600px;
        margin-right: 96px;
        margin-bottom: 40px;
        border-bottom: 1px solid #E3001B;
        padding-bottom: 20px;
    }

    .main-contact-page-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #676767;
        margin-bottom: 20px;
    }

    .title-in-red {
        color: #E3001B;
        font-size: 20px;
    }

    .main-contact-page-left,
    .main-contact-page-right {
        width: 298px; /* was 45% show peter*/
        display: inline-block;
    }

    .main-contact-page-left {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 33px;
        color: #676767;
    }

    .main-contact-page-right {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 34px;
        color: #676767;
    }

    .main-contact-page-right-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .main-contact-page-right-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .main-contact-page-right-faxContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .main-contact-page-right-faxContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }
    .main-contact-container:nth-child(2n+2){
        margin-right:0 ;
    }
}