@media screen and (min-width: 1400px){
    .twoColumnsNews {
        width: 1300px;
        margin: 36px auto 0;
    }

    .leftColumnNewsContainer {
        display: inline-block;
        /* width: 996px; */
        width:1080px;
    }

    .leftColumnNews {
        /* width: 886px; */
        width: 980px;
    }

    .rightSidebarContactContainer {
        display: inline-block;
    }

    .rightColumnNewsContainer {
        display: inline-block;
        max-width: 300px;
        vertical-align: top;
        border-top: 1px solid #E3001B;
    }

    .rightSidebarContact {
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 18px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .twoColumnsNews {
        width: 1100px;
        margin: 36px auto 0;
    }

    .leftColumnNewsContainer {
        display: inline-block;
        width: 870px;
    }

    .leftColumnNews {
        width: 770px;
    }

    .rightSidebarContactContainer {
        display: inline-block;
    }

    .rightColumnNewsContainer {
        display: inline-block;
        max-width: 300px;
        vertical-align: top;
        border-top: 1px solid #E3001B;
    }

    .rightSidebarContact {
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 18px;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .twoColumnsNews {
        width: 922px;
        margin: 36px auto 0;
    }

    .leftColumnNewsContainer {
        display: inline-block;
        width: 720px;
    }

    .leftColumnNews {
        width: 660px;
    }

    .rightSidebarContactContainer {
        display: inline-block;
    }

    .rightColumnNewsContainer {
        display: inline-block;
        max-width: 196px;
        vertical-align: top;
        border-top: 1px solid #E3001B;
    }

    .rightSidebarContact {
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 18px;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .twoColumnsNews {
        width: 666px;
        margin: 36px auto 0;
    }

    .leftColumnNewsContainer {
        display: inline-block;
        width: 520px;
    }

    .leftColumnNews {
        width: 500px;
    }

    .rightSidebarContactContainer {
        display: inline-block;
    }

    .rightColumnNewsContainer {
        display: inline-block;
        max-width: 142px;
        vertical-align: top;
        border-top: 1px solid #E3001B;
    }

    .rightSidebarContact {
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 18px;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .twoColumnsNews {
        width: 428px;
        margin: 36px auto 0;
    }

    .leftColumnNewsContainer {
        display: block;
        width: 100%;
    }

    .leftColumnNews {
        width: 100%;
    }

    .rightSidebarContactContainer {
        display: block;
        padding-top:30px;
    }

    .rightColumnNewsContainer {
        display: inline-block;
        width: 428px;
        vertical-align: top;
        border-top: 1px solid #E3001B;
    }

    .rightSidebarContact {
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 18px;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .twoColumnsNews {
        width: 300px;
        margin: 36px auto 0;
    }

    .leftColumnNewsContainer {
        display: block;
        width: 100%;
    }

    .leftColumnNews {
        width: 100%;
    }

    .rightSidebarContactContainer {
        display: block;
    }

    .rightColumnNewsContainer {
        display: inline-block;
        width: 300px;
        vertical-align: top;
        border-top: 1px solid #E3001B;
    }

    .rightSidebarContact {
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 18px;
    }
}

@media screen and (max-width: 350px){
    .twoColumnsNews {
        width: 300px;
        margin: 36px auto 0;
    }

    .leftColumnNewsContainer {
        display: block;
        width: 100%;
    }

    .leftColumnNews {
        width: 100%;
    }

    .rightSidebarContactContainer {
        display: block;
    }

    .rightColumnNewsContainer {
        display: inline-block;
        width: 300px;
        vertical-align: top;
        border-top: 1px solid #E3001B;
    }

    .rightSidebarContact {
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 18px;
    }
}

@media print {
    .twoColumnsNews {
        width: 1300px;
        margin: 36px auto 0;
    }

    .leftColumnNewsContainer {
        display: inline-block;
        /* width: 996px; */
        width:1080px;
    }

    .leftColumnNews {
        /* width: 886px; */
        width: 980px;
    }

    .rightSidebarContactContainer {
        display: inline-block;
    }

    .rightColumnNewsContainer {
        display: inline-block;
        max-width: 300px;
        vertical-align: top;
        border-top: 1px solid #E3001B;
    }

    .rightSidebarContact {
        border-bottom: 1px solid #9A9A9A;
        padding-bottom: 18px;
    }
}