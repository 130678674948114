/*!
    This CSS resource incorporates links to font software which is the valuable copyrighted
    property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install,
    redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
    Imaging with any questions regarding Web Fonts:  http://www.fonts.com
*/
@font-face {
    font-family: 'Istok Bold';
    src: url('../fonts/istok/Istok-Bold.ttf') format('truetype'),
    url('../fonts/istok/Istok-Bold.eot') format('eot'),
    url('../fonts/istok/Istok-Bold.otf') format('opentype'),
    url('../fonts/istok/Istok-Bold.woff2') format('woff2'),
    url('../fonts/istok/Istok-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Istok Bold Italic';
    src: url('../fonts/istok/Istok-BoldItalic.ttf') format('truetype'),
    url('../fonts/istok/Istok-BoldItalic.eot') format('eot'),
    url('../fonts/istok/Istok-BoldItalic.otf') format('opentype'),
    url('../fonts/istok/Istok-BoldItalic.woff2') format('woff2'),
    url('../fonts/istok/Istok-BoldItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Istok Italic';
    src: url('../fonts/istok/Istok-Italic.ttf') format('truetype'),
    url('../fonts/istok/Istok-Italic.eot') format('eot'),
    url('../fonts/istok/Istok-Italic.otf') format('opentype'),
    url('../fonts/istok/Istok-Italic.woff2') format('woff2'),
    url('../fonts/istok/Istok-Italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Istok Regular';
    src: url('../fonts/istok/Istok-Regular.ttf') format('truetype'),
    url('../fonts/istok/Istok-Regular.eot') format('eot'),
    url('../fonts/istok/Istok-Regular.otf') format('opentype'),
    url('../fonts/istok/Istok-Regular.woff2') format('woff2'),
    url('../fonts/istok/Istok-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}