@media screen and (min-width: 1400px){
    #error-container{
        width:1300px;
        margin: 0 auto;
        text-align: center;
        padding:250px 0px;
    }
    .error-title{
        font-size:60px;
        line-height: 55px;
        color:#E3001B;
        font-family: "Istok Regular",sans-serif;
        text-transform: uppercase;
        padding-bottom:20px;
    }
    .error-subTitle{
        font-family: "Istok Regular",sans-serif;
        font-size:60px;
        line-height: 55px;
        color:#9A9A9A;
        text-transform: uppercase;

    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    #error-container{
        width:1100px;
        margin: 0 auto;
        text-align: center;
        padding:250px 0px;
    }
    .error-title{
        font-size:60px;
        line-height: 55px;
        color:#E3001B;
        font-family: "Istok Regular",sans-serif;
        text-transform: uppercase;
        padding-bottom:20px;
    }
    .error-subTitle{
        font-family: "Istok Regular",sans-serif;
        font-size:60px;
        line-height: 55px;
        color:#9A9A9A;
        text-transform: uppercase;

    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    #error-container{
        width:922px;
        margin: 0 auto;
        text-align: center;
        padding:250px 0px;
    }
    .error-title{
        font-size:60px;
        line-height: 55px;
        color:#E3001B;
        font-family: "Istok Regular",sans-serif;
        text-transform: uppercase;
        padding-bottom:20px;
    }
    .error-subTitle{
        font-family: "Istok Regular",sans-serif;
        font-size:60px;
        line-height: 55px;
        color:#9A9A9A;
        text-transform: uppercase;

    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    #error-container{
        width:666px;
        margin: 0 auto;
        text-align: center;
        padding:100px 0px;
        height:500px;
    }
    .error-title{
        font-size:60px;
        line-height: 55px;
        color:#E3001B;
        font-family: "Istok Regular",sans-serif;
        text-transform: uppercase;
        padding-bottom:20px;
    }
    .error-subTitle{
        font-family: "Istok Regular",sans-serif;
        font-size:60px;
        line-height: 55px;
        color:#9A9A9A;
        text-transform: uppercase;

    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    #error-container{
        width:428px;
        margin: 0 auto;
        text-align: center;
        padding:100px 0px;
        height:500px;
    }
    .error-title{
        font-size:60px;
        line-height: 55px;
        color:#E3001B;
        font-family: "Istok Regular",sans-serif;
        text-transform: uppercase;
        padding-bottom:20px;
    }
    .error-subTitle{
        font-family: "Istok Regular",sans-serif;
        font-size:60px;
        line-height: 55px;
        color:#9A9A9A;
        text-transform: uppercase;

    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    #error-container{
        width:300px;
        margin: 0 auto;
        text-align: center;
        padding:100px 0px;
        height:500px;
    }
    .error-title{
        font-size:56px;
        line-height: 55px;
        color:#E3001B;
        font-family: "Istok Regular",sans-serif;
        text-transform: uppercase;
        padding-bottom:20px;
    }
    .error-subTitle{
        font-family: "Istok Regular",sans-serif;
        font-size:26px;
        line-height: 55px;
        color:#9A9A9A;
        text-transform: uppercase;
    }

}

@media screen and (max-width: 350px){
    #error-container{
        width:300px;
        margin: 0 auto;
        text-align: center;
        padding:100px 0px;
        height:500px;
    }
    .error-title{
        font-size:56px;
        line-height: 55px;
        color:#E3001B;
        font-family: "Istok Regular",sans-serif;
        text-transform: uppercase;
        padding-bottom:20px;
    }
    .error-subTitle{
        font-family: "Istok Regular",sans-serif;
        font-size:26px;
        line-height: 55px;
        color:#9A9A9A;
        text-transform: uppercase;

    }
}

@media print {
    #error-container {
        width: 1300px;
        margin: 0 auto;
        text-align: center;
        padding: 100px 0px;
        height: 500px;
    }

    .error-title {
        font-size: 60px;
        line-height: 55px;
        color: #E3001B;
        font-family: "Istok Regular", sans-serif;
        text-transform: uppercase;
        padding-bottom: 20px;
    }

    .error-subTitle {
        font-family: "Istok Regular", sans-serif;
        font-size: 60px;
        line-height: 55px;
        color: #9A9A9A;
        text-transform: uppercase;
    }
}