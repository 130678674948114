table{
    margin: 20px 0px;
}
/*AGB/Impressum Styles*/
.richText-content-container p{
    margin: 10px 0px;
    font-family: "Istok Regular", sans-serif;
    color: #676767;
}
.richText-content-container strong{
    font-weight: bold;
}
.richText-content-container table td{
    border: 1px solid #676767;
    padding:10px;
}
/*AGB/Impressum Styles*/
@media screen and (min-width: 1400px) {
    .twoColumnsCategory {
        display: inline-block;
        width: 1000px;
        vertical-align: top;
        margin-top: 30px;
        margin-left: 106px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
    }

    .leftColumnCategoryContainer {
        width: 700px;
        display: inline-block;
        margin-right: 94px;
    }

    .category-description strong {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
    }

    .category-description p {
        margin-bottom: 10px;
    }

    .category-description ul {
        list-style: none;
        padding: 10px 0px;

    }

    .category-description ul li::before {
        content: "\2022";
        color: red;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: 0em;
        font-size: 26px;
    }

    .category-description ul li span {
        vertical-align: top;
    }

    .rightColumnCategoryContainer {
        display: inline-block;
        vertical-align: top;
        width: 200px;
    }

    .rightSidebarCategory {
        border-bottom: 1px solid #9A9A9A;
    }

    .category-contact-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 18px;
        line-height: 25px;
        color: #E3001B;
    }

    .subcategory-contact-title {
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 25px;
        color: #E3001B;
    }

    .subcategory-company-title{
        font-family: "Istok Regular", sans-serif;
        font-size: 14px;
        line-height: 25px;
        color: #E3001B;
    }

    .rightSidebarCategory-addressField{
        color: #676767;
        font-family: "Istok Regular",sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding-top: 4px;
        padding-bottom: 3px;
    }

    .rightSidebarCategory-person-name {
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding-top: 8px;
        padding-bottom: 6px;
    }

    .rightSidebarCategory-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 11px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 2px;
    }

    .rightSidebarCategory-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarCategory-emailContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 11px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 2px;
    }

    .rightSidebarCategory-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }
    .rightSidebarCategory-external-linkContainer i{
        color: #9A9A9A;
        padding-right: 13px;
    }
    .rightSidebarCategory-external-linkContainer a{
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }

    .rightSidebarCategory-download-link {
        border-bottom: 1px solid #9A9A9A;
    }

    .rightSidebarCategory-download-link i {
        color: #9A9A9A;
        padding-right: 13px;
    }

    .rightSidebarCategory-download-link a {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }

    .category-description .category-description-table {
        border: 1px solid #9A9A9A;

    }
    .category-description p img{
        max-width:100%;
    }

}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .twoColumnsCategory {
        display: inline-block;
        width: 850px;
        vertical-align: top;
        margin-top: 30px;
        margin-left: 52px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
    }

    .leftColumnCategoryContainer {
        width: 600px;
        display: inline-block;
        margin-right: 44px;
    }

    .category-description strong {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
    }

    .category-description p {
        margin-bottom: 10px;
    }

    .category-description ul {
        list-style: none;
        padding: 10px 0px;

    }

    .category-description ul li::before {
        content: "\2022";
        color: red;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: 0em;
        font-size: 26px;
    }

    .category-description ul li span {
        vertical-align: top;
    }

    .rightColumnCategoryContainer {
        display: inline-block;
        vertical-align: top;
        width: 200px;
    }

    .rightSidebarCategory {
        border-bottom: 1px solid #9A9A9A;
    }

    .category-contact-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 18px;
        line-height: 25px;
        color: #E3001B;
    }

    .subcategory-contact-title {
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 25px;
        color: #E3001B;
    }

    .subcategory-company-title{
        font-family: "Istok Regular", sans-serif;
        font-size: 14px;
        line-height: 25px;
        color: #E3001B;
    }

    .rightSidebarCategory-addressField{
        color: #676767;
        font-family: "Istok Regular",sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding-top: 4px;
        padding-bottom: 3px;
    }

    .rightSidebarCategory-person-name {
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding-top: 8px;
        padding-bottom: 6px;
    }

    .rightSidebarCategory-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 11px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 2px;
    }

    .rightSidebarCategory-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarCategory-emailContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 11px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 2px;
    }

    .rightSidebarCategory-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }
    .rightSidebarCategory-external-linkContainer i{
        color: #9A9A9A;
        padding-right: 13px;
    }
    .rightSidebarCategory-external-linkContainer a{
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }

    .rightSidebarCategory-download-link {
        border-bottom: 1px solid #9A9A9A;
    }

    .rightSidebarCategory-download-link i {
        color: #9A9A9A;
        padding-right: 13px;
    }

    .rightSidebarCategory-download-link a {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }
    .category-description p img{
        max-width:100%;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .twoColumnsCategory {
        display: inline-block;
        width: 670px;
        vertical-align: top;
        margin-top: 30px;
        margin-left: 52px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
    }

    .leftColumnCategoryContainer {
        width: 680px;
        display: inline-block;
        margin-right: 44px;
    }

    .category-description strong {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
    }

    .category-description p {
        margin-bottom: 10px;
    }

    .category-description ul {
        list-style: none;
        padding: 10px 0px;

    }

    .category-description ul li::before {
        content: "\2022";
        color: red;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: 0em;
        font-size: 26px;
    }

    .category-description ul li span {
        vertical-align: top;
    }

    .rightColumnCategoryContainer {
        display: block;
        width: 100%;
        margin-top: 30px;
    }

    .rightSidebarCategory {
        border-bottom: 1px solid #9A9A9A;
    }

    .category-contact-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 24px;
        line-height: 25px;
        color: #E3001B;
    }

    .subcategory-contact-title {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 25px;
        color: #E3001B;
    }

    .subcategory-company-title{
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 25px;
        color: #E3001B;
    }

    .subcategory-company-title {
        font-family: "Istok Regular", sans-serif;
        font-size: 14px;
        line-height: 25px;
        color: #E3001B;
    }

    .rightSidebarCategoryContainer{
        padding-top:20px;
    }

    .rightSidebarCategory-addressField{
        color: #676767;
        font-family: "Istok Regular",sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding-top: 4px;
        padding-bottom: 3px;
    }

    .rightSidebarCategory-person-name {
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding-top: 8px;
        padding-bottom: 6px;
    }

    .rightSidebarCategory-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarCategory-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarCategory-emailContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarCategory-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }
    .rightSidebarCategory-external-linkContainer i{
        color: #9A9A9A;
        padding-right: 13px;
    }
    .rightSidebarCategory-external-linkContainer a{
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }

    .rightSidebarCategory-download-link {
        padding: 20px 0px;
    }

    .rightSidebarCategory-download-link i {
        color: #9A9A9A;
        padding-right: 13px;
    }

    .rightSidebarCategory-download-link a {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: block;
        width:300px;
        text-align: center;
        border: 1px solid #9A9A9A;
    }
    .category-description p img{
        max-width:100%;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .twoColumnsCategory {
        display: block;
        vertical-align: top;
        margin-top: 30px;
        font-family: "Istok Regular",sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
    }

    .leftColumnCategoryContainer {
        display: block;
    }

    .category-description strong {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
    }

    .category-description p {
        margin-bottom: 10px;
    }

    .category-description ul {
        list-style: none;
        padding: 10px 0px;

    }

    .category-description ul li::before {
        content: "\2022";
        color: red;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: 0em;
        font-size: 26px;
    }

    .category-description ul li span {
        vertical-align: top;
    }

    .rightColumnCategoryContainer {
        display: block;
        width: 100%;
        margin-top: 30px;
    }

    .rightSidebarCategory {
        border-bottom: 1px solid #9A9A9A;
    }

    .category-contact-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 24px;
        line-height: 25px;
        color: #E3001B;
    }

    .subcategory-contact-title {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 25px;
        color: #E3001B;
    }

    .subcategory-company-title{
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 25px;
        color: #E3001B;
    }

    .rightSidebarCategoryContainer{
        padding-top:20px;
    }

    .rightSidebarCategory:last-child{
        border:none;
    }

    .rightSidebarCategory-addressField{
        color: #676767;
        font-family: "Istok Regular",sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding-top: 4px;
        padding-bottom: 3px;
    }

    .rightSidebarCategory-person-name {
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding-top: 8px;
        padding-bottom: 6px;
    }

    .rightSidebarCategory-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarCategory-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarCategory-emailContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarCategory-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }
    .rightSidebarCategory-external-linkContainer i{
        color: #9A9A9A;
        padding-right: 13px;
    }
    .rightSidebarCategory-external-linkContainer a{
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }

    .rightSidebarCategory-download-link {
        padding: 20px 0px;
    }


    .rightSidebarCategory-download-link i {
        color: #9A9A9A;
        padding-right: 13px;
    }

    .rightSidebarCategory-download-link a {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: block;
        width:300px;
        text-align: center;
        border: 1px solid #9A9A9A;
    }
    .category-description p img{
        max-width:100%;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .twoColumnsCategory {
        display: block;
        vertical-align: top;
        margin-top: 30px;
        font-family: "Istok Regular",sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
    }

    .leftColumnCategoryContainer {
        display: block;
    }

    .category-description strong {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
    }

    .category-description p {
        margin-bottom: 10px;
    }

    .category-description ul {
        list-style: none;
        padding: 10px 0px;
    }

    .category-description ul li::before {
        content: "\2022";
        color: red;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: 0em;
        font-size: 26px;
    }

    .category-description ul li span {
        vertical-align: top;
    }

    .rightColumnCategoryContainer {
        display: block;
        width: 100%;
        margin-top: 30px;
    }

    .rightSidebarCategory {
        border-bottom: 1px solid #9A9A9A;
    }

    .category-contact-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 24px;
        line-height: 25px;
        color: #E3001B;
        padding-bottom: 13px;
    }


    .rightSidebarCategoryContainer{
        padding-top:20px;
    }
    .rightSidebarCategory:last-child{
        border:none;
    }

    .rightSidebarCategory-addressField{
        color: #676767;
        font-family: "Istok Regular",sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding-top: 4px;
        padding-bottom: 3px;
    }

    .rightSidebarCategory-person-name {
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding-top: 8px;
        padding-bottom: 6px;
    }

    .rightSidebarCategory-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarCategory-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarCategory-emailContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarCategory-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }
    .rightSidebarCategory-external-linkContainer i{
        color: #9A9A9A;
        padding-right: 13px;
    }
    .rightSidebarCategory-external-linkContainer a{
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }
    .subcategory-contact-title {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #E3001B;
        padding-top: 13px;
    }

    .subcategory-company-title{
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 25px;
        color: #E3001B;
    }

    .rightSidebarCategory-download-link {
        padding: 20px 0px;
    }


    .rightSidebarCategory-download-link i {
        color: #9A9A9A;
        padding-right: 13px;
    }

    .rightSidebarCategory-download-link a {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: block;
        width:300px;
        text-align: center;
        border: 1px solid #9A9A9A;
    }
    .category-description p img{
        max-width:100%;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .twoColumnsCategory {
        display: block;
        vertical-align: top;
        margin-top: 30px;
        font-family: "Istok Regular",sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
    }

    .leftColumnCategoryContainer {
        display: block;
    }

    .category-description strong {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
    }

    .category-description p {
        margin-bottom: 10px;
    }

    .category-description ul {
        list-style: none;
        padding:10px 0px;
    }

    .category-description ul li::before {
        content: "\2022";
        color: red;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: 0em;
        font-size: 26px;
    }

    .category-description ul li span {
        vertical-align: top;
    }

    .rightColumnCategoryContainer {
        display: block;
        width: 100%;
        margin-top: 30px;
    }

    .rightSidebarCategory {
        border-bottom: 1px solid #9A9A9A;
    }

    .category-contact-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 24px;
        line-height: 25px;
        color: #E3001B;
        padding-bottom: 13px;
    }


    .rightSidebarCategoryContainer{
        padding-top:20px;
    }
    .rightSidebarCategory:last-child{
        border:none;
    }

    .rightSidebarCategory-addressField{
        color: #676767;
        font-family: "Istok Regular",sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding-top: 4px;
        padding-bottom: 3px;
    }

    .rightSidebarCategory-person-name {
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding-top: 8px;
        padding-bottom: 6px;
    }

    .rightSidebarCategory-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarCategory-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarCategory-emailContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarCategory-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }
    .rightSidebarCategory-external-linkContainer i{
        color: #9A9A9A;
        padding-right: 13px;
    }
    .rightSidebarCategory-external-linkContainer a{
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }

    .subcategory-company-title{
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 25px;
        color: #E3001B;
    }

    .subcategory-contact-title {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #E3001B;
        padding-top: 13px;
    }

    .rightSidebarCategory-download-link {
        padding: 20px 0px;
    }


    .rightSidebarCategory-download-link i {
        color: #9A9A9A;
        padding-right: 13px;
    }

    .rightSidebarCategory-download-link a {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: block;
        width:100%;
        text-align: center;
        border: 1px solid #9a9a9a;
    }
    .category-description p img {
        max-width:100%;
    }
}

@media screen and (max-width: 350px) {
    .twoColumnsCategory {
        display: block;
        vertical-align: top;
        margin-top: 30px;
        font-family: "Istok Regular",sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
    }

    .leftColumnCategoryContainer {
        display: block;
    }

    .category-description strong {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
    }

    .category-description p {
        margin-bottom: 10px;
    }

    .category-description ul {
        list-style: none;
        padding: 10px 0px;
    }

    .category-description ul li::before {
        content: "\2022";
        color: red;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: 0em;
        font-size: 26px;
    }

    .category-description ul li span {
        vertical-align: top;
    }

    .rightColumnCategoryContainer {
        display: block;
        width: 100%;
        margin-top: 30px;
    }

    .rightSidebarCategory {
        border-bottom: 1px solid #9A9A9A;
    }

    .category-contact-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 24px;
        line-height: 25px;
        color: #E3001B;
        padding-bottom: 13px;

    }

    .rightSidebarCategoryContainer{
        padding-top:20px;
    }

    .rightSidebarCategory:last-child{
        border:none;
    }

    .rightSidebarCategory-addressField{
        color: #676767;
        font-family: "Istok Regular",sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding-top: 4px;
        padding-bottom: 3px;
    }

    .rightSidebarCategory-person-name {
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding-top: 8px;
        padding-bottom: 6px;
    }

    .rightSidebarCategory-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarCategory-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarCategory-emailContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarCategory-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarCategory-external-linkContainer i{
        color: #9A9A9A;
        padding-right: 13px;
    }
    .rightSidebarCategory-external-linkContainer a{
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }

    .subcategory-contact-title {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #E3001B;
        padding-top: 13px;
    }

    .subcategory-company-title{
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 25px;
        color: #E3001B;
    }

    .rightSidebarCategory-download-link {
        padding: 20px 0px;
    }


    .rightSidebarCategory-download-link i {
        color: #9A9A9A;
        padding-right: 13px;
    }

    .rightSidebarCategory-download-link a {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: block;
        width:100%;
        text-align: center;
        border: 1px solid #9a9a9a;
    }

    .category-description p img {
        max-width:100%;
    }

}

@media print {

    .twoColumnsCategory {
        display: inline-block;
        width: 1000px;
        vertical-align: top;
        margin-top: 30px;
        margin-left: 106px;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
    }

    .leftColumnCategoryContainer {
        width: 700px;
        display: inline-block;
        margin-right: 94px;
    }

    .category-description strong {
        font-family: "Istok Bold", sans-serif;
        font-size: 16px;
        line-height: 22px;
    }

    .category-description p {
        margin-bottom: 10px;
    }

    .category-description ul {
        list-style: none;
        padding: 10px 0px;

    }

    .category-description ul li::before {
        content: "\2022";
        color: red;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: 0em;
        font-size: 26px;
    }

    .category-description ul li span {
        vertical-align: top;
    }

    .rightColumnCategoryContainer {
        display: inline-block;
        vertical-align: top;
        width: 200px;
    }

    .rightSidebarCategory {
        border-bottom: 1px solid #9A9A9A;
    }

    .category-contact-title {
        font-family: "Istok Bold", sans-serif;
        font-size: 18px;
        line-height: 25px;
        color: #E3001B;
    }


    .subcategory-contact-title {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 25px;
        color: #E3001B;
    }

    .subcategory-company-title{
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 25px;
        color: #E3001B;
    }

    .rightSidebarCategory-addressField{
        color: #676767;
        font-family: "Istok Regular",sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding-top: 4px;
        padding-bottom: 3px;
    }

    .rightSidebarCategory-person-name {
        color: #676767;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        padding-top: 8px;
        padding-bottom: 6px;
    }

    .rightSidebarCategory-phoneContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 13px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 10px;
    }

    .rightSidebarCategory-phoneContainer i {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }

    .rightSidebarCategory-emailContainer a {
        color: #676767;
        font-family: "Istok Bold", sans-serif;
        font-size: 11px;
        line-height: 34px;
        text-decoration: none;
        padding-left: 2px;
    }

    .rightSidebarCategory-emailContainer i {
        font-weight: 500;
        color: #9A9A9A;
        vertical-align: top;
        padding-top: 9px;
    }
    .rightSidebarCategory-external-linkContainer i{
        color: #9A9A9A;
        padding-right: 13px;
    }
    .rightSidebarCategory-external-linkContainer a{
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }

    .rightSidebarCategory-download-link {
    }

    .rightSidebarCategory-download-link i {
        color: #9A9A9A;
        padding-right: 13px;
    }

    .rightSidebarCategory-download-link a {
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #E3001B;
        text-decoration: none;
        padding-top: 18px;
        padding-bottom: 18px;
        display: inline-block;
    }


}
/* Utility styles that do not depend on media queries */
.category-description table {
    border: 1px solid #9A9A9A;

}
.category-description table thead tr th{
    color:#FFF;
    padding: 7px;
}
.category-description table tbody tr td{
    color:#9A9A9A;
    padding: 7px;
    border: 1px solid #9A9A9A;
}