@media screen and (min-width: 1400px){
    .herospace {
        width: 100%;
        height: 230px;
        border-bottom: 5px solid #E3001B;
    }

    .herospaceImg {
        width: 100%;
        height: 230px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .herospaceTitle {
        text-align: center;
        vertical-align: top;
        font-family: "Istok Regular", sans-serif;
        font-size: 30px;
        line-height: 35px;
        text-transform: uppercase;
        letter-spacing: 7px;
        color: white;
        margin-top: -130px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .herospace {
        width: 100%;
        height: 230px;
        border-bottom: 5px solid #E3001B;
    }

    .herospaceImg {
        width: 100%;
        height: 230px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .herospaceTitle {
        text-align: center;
        vertical-align: top;
        font-family: "Istok Regular", sans-serif;
        font-size: 30px;
        line-height: 35px;
        text-transform: uppercase;
        letter-spacing: 7px;
        color: white;
        margin-top: -130px;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .herospace {
        width: 100%;
        height: 230px;
        border-bottom: 5px solid #E3001B;
    }

    .herospaceImg {
        width: 100%;
        height: 230px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .herospaceTitle {
        text-align: center;
        vertical-align: top;
        font-family: "Istok Regular", sans-serif;
        font-size: 30px;
        line-height: 35px;
        text-transform: uppercase;
        letter-spacing: 7px;
        color: white;
        margin-top: -130px;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .herospace {
        width: 100%;
        height: 230px;
        border-bottom: 5px solid #E3001B;
    }

    .herospaceImg {
        width: 100%;
        height: 230px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .herospaceTitle {
        text-align: center;
        vertical-align: top;
        font-family: "Istok Regular", sans-serif;
        font-size: 30px;
        line-height: 35px;
        text-transform: uppercase;
        letter-spacing: 7px;
        color: white;
        margin-top: -130px;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .herospace {
        width: 100%;
        height: 180px;
        border-bottom: 5px solid #E3001B;
    }

    .herospaceImg {
        width: 100%;
        height: 180px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .herospaceTitle {
        text-align: center;
        vertical-align: top;
        font-family: "Istok Regular", sans-serif;
        font-size: 30px;
        line-height: 35px;
        text-transform: uppercase;
        letter-spacing: 7px;
        color: white;
        margin-top: -100px;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .herospace {
        width: 100%;
        height: 180px;
        border-bottom: 5px solid #E3001B;
    }

    .herospaceImg {
        width: 100%;
        height: 180px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .herospaceTitle {
        text-align: center;
        vertical-align: top;
        font-family: "Istok Regular", sans-serif;
        font-size: 26px;
        line-height: 35px;
        text-transform: uppercase;
        letter-spacing: 7px;
        color: white;
        margin-top: -100px;
    }
}

@media screen and (max-width: 350px){
    .herospace {
        width: 100%;
        height: 180px;
        border-bottom: 5px solid #E3001B;
    }

    .herospaceImg {
        width: 100%;
        height: 180px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .herospaceTitle {
        text-align: center;
        vertical-align: top;
        font-family: "Istok Regular", sans-serif;
        font-size: 24px;
        line-height: 35px;
        text-transform: uppercase;
        letter-spacing: 7px;
        color: white;
        margin-top: -100px;
    }
}

@media print {
    .herospace {
        width: 100%;
        height: 230px;
        border-bottom: 5px solid #E3001B;
    }

    .herospaceImg {
        width: 100%;
        height: 230px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .herospaceTitle {
        text-align: center;
        vertical-align: top;
        font-family: "Istok Regular", sans-serif;
        font-size: 30px;
        line-height: 35px;
        text-transform: uppercase;
        letter-spacing: 7px;
        color: white;
        margin-top: -130px;
    }
}