@media screen and (min-width: 1400px){
    .category-main-container {
        width: 1300px;
        margin: 0 auto;
    }

    .left-category-navigation {
        display: inline-block;
        width: 190px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .left-category-navigationLink {
        display: block;
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        border-bottom: 1px solid #676767;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .left-category-navigationLink-active{
        color:#E3001B;
    }

    .left-category-navigationLink:hover {
        color: #E3001B;
    }

    .left-category-main-navigationLink {
        display: block;
        text-decoration: none;
        font-family: "Istok Bold", sans-serif;
        font-size: 18px;
        line-height: 25px;
        color: #E3001B;
        border-top: 1px solid #E3001B;
        border-bottom: 1px solid #676767;
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .category-main-container {
        width: 1100px;
        margin: 0 auto;
    }

    .left-category-navigation {
        display: inline-block;
        width: 190px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .left-category-navigationLink {
        display: block;
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        border-bottom: 1px solid #676767;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .left-category-navigationLink:hover {
        color: #E3001B;
    }
    .left-category-navigationLink-active{
        color:#E3001B;
    }
    .left-category-main-navigationLink {
        display: block;
        text-decoration: none;
        font-family: "Istok Bold", sans-serif;
        font-size: 18px;
        line-height: 25px;
        color: #E3001B;
        border-top: 1px solid #E3001B;
        border-bottom: 1px solid #676767;
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .category-main-container {
        width: 922px;
        margin: 0 auto;
    }

    .left-category-navigation {
        display: inline-block;
        width: 190px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .left-category-navigationLink {
        display: block;
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        border-bottom: 1px solid #676767;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .left-category-navigationLink:hover {
        color: #E3001B;
    }

    .left-category-navigationLink-active{
        color:#E3001B;
    }

    .left-category-main-navigationLink {
        display: block;
        text-decoration: none;
        font-family: "Istok Bold", sans-serif;
        font-size: 18px;
        line-height: 25px;
        color: #E3001B;
        border-top: 1px solid #E3001B;
        border-bottom: 1px solid #676767;
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .category-main-container {
        width: 666px;
        margin: 0 auto;
    }

    .left-category-navigation {
        display: none;
    }

}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .category-main-container {
        width: 428px;
        margin: 0 auto;
    }

    .left-category-navigation {
        display: none;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .category-main-container {
        width: 300px;
        margin: 0 auto;
    }

    .left-category-navigation {
        display: none;
    }
}

@media screen and (max-width: 350px){
    .category-main-container {
        width: 300px;
        margin: 0 auto;
    }

    .left-category-navigation {
        display: none;
    }
}

@media print {
    .category-main-container {
        width: 1300px;
        margin: 0 auto;
    }

    .left-category-navigation {
        display: inline-block;
        width: 190px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .left-category-navigationLink {
        display: block;
        text-decoration: none;
        font-family: "Istok Regular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #676767;
        border-bottom: 1px solid #676767;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .left-category-navigationLink:hover {
        color: #E3001B;
    }

    .left-category-main-navigationLink {
        display: block;
        text-decoration: none;
        font-family: "Istok Bold", sans-serif;
        font-size: 18px;
        line-height: 25px;
        color: #E3001B;
        border-top: 1px solid #E3001B;
        border-bottom: 1px solid #676767;
        padding-top: 12px;
        padding-bottom: 12px;
    }
}