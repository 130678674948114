@media screen and (min-width: 1400px) {

    .elkuch-video {
        width: 100%;
        margin:20px auto;
    }

    .elkuch-video .videoFrame {
        width:100%;
        height:300px;
    }

    .elkuch-video .video-description {
        font-family: "Istok Regular", sans-serif;
        font-size:16px;
    }

}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .elkuch-video {
        width: 100%;
        margin:20px auto;
    }

    .elkuch-video .videoFrame {
        width:100%;
        height:300px;
    }

    .elkuch-video .video-description {
        font-family: "Istok Regular", sans-serif;
        font-size:16px;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {

    .elkuch-video {
        width: 100%;
        margin:20px auto;
    }

    .elkuch-video .videoFrame {
        width:100%;
        height:300px;
    }

    .elkuch-video .video-description {
        font-family: "Istok Regular", sans-serif;
        font-size:16px;
    }

}

@media screen and (min-width: 766px) and (max-width: 1022px) {

    .elkuch-video {
        width: 100%;
        margin:20px auto;
    }

    .elkuch-video .videoFrame {
        width:100%;
        height:300px;
    }

    .elkuch-video .video-description {
        font-family: "Istok Regular", sans-serif;
        font-size:16px;
    }

}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {

    .elkuch-video {
        width: 100%;
        margin:20px auto;
    }

    .elkuch-video .videoFrame {
        width:100%;
        height:214px;
    }

    .elkuch-video .video-description {
        font-family: "Istok Regular", sans-serif;
        font-size:16px;
    }

}

@media screen and (min-width: 350px) and (max-width: 478px) {

    .elkuch-video {
        width: 100%;
        margin:20px auto;
    }

    .elkuch-video .videoFrame {
        width:100%;
        height:180px;
    }

    .elkuch-video .video-description {
        font-family: "Istok Regular", sans-serif;
        font-size:16px;
    }

}

@media screen and (max-width: 350px) {

    .elkuch-video {
        width: 100%;
        margin:20px auto;
    }

    .elkuch-video .videoFrame {
        width:100%;
        height:180px;
    }

    .elkuch-video .video-description {
        font-family: "Istok Regular", sans-serif;
        font-size:16px;
    }

}

@media print {

    .elkuch-video {
        width: 100%;
        margin:20px auto;
    }

    .elkuch-video .videoFrame {
        width:100%;
        height:300px;
    }

    .elkuch-video .video-description {
        font-family: "Istok Regular", sans-serif;
        font-size:16px;
    }

}