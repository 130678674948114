@media screen and (min-width: 1400px){
    .fileImageTextContainer {
        border-bottom: 1px solid #9A9A9A;
        margin-bottom: 10px;
    }

    .fileContainerTitle {
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #E3001B;
        border-bottom: 1px solid #E3001B;
        padding-bottom: 11px;
    }

    .fileImageTextLeftSide {
        display: inline-block;
        max-width: 258px;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .fileImageTextLeftSide embed {
        display: block;
        border: none;
    }

    .fileImageText-download-container {
        border: 1px solid #9A9A9A;
        border-top: none;
    }

    .fileImageText-link {
        height: 43px;
        background: #9A9A9A;
        display: block;
        text-decoration: none;
    }

    .fileImageText-link i {
        display: inline-block;
        vertical-align: top;
        padding-top: 14px;
        padding-left: 12px;
        padding-right: 4px;
        color: white;
    }

    .fileImageText-link-title {
        display: inline-block;
        vertical-align: top;
        padding-top: 12px;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-transform: uppercase;
    }

    .fileImageText-description {
        display: inline-block;
        vertical-align: top;
        margin-top: 30px;
        margin-left: 60px;
        font-family: "Istok Regular", sans-serif;
        line-height: 25px;
    }

    .fileImageText-description strong {
        font-family: "Istok Bold", sans-serif;
        line-height: 25px;
    }

    .fileImageText-description p {
        margin-bottom: 10px;
    }

    .fileImageText-description ul {
        list-style: none;
    }

    .fileImageText-description ul li::before {
        content: "\2022";
        color: red;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: 0em;
        font-size: 26px;
    }

    .fileImageText-description ul li span {
        vertical-align: top;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .fileImageTextContainer {
        border-bottom: 1px solid #9A9A9A;
        margin-bottom: 10px;
    }

    .fileContainerTitle {
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #E3001B;
        border-bottom: 1px solid #E3001B;
        padding-bottom: 11px;
    }

    .fileImageTextLeftSide {
        display: inline-block;
        max-width: 258px;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .fileImageTextLeftSide embed {
        display: block;
        border: none;
    }

    .fileImageText-download-container {
        border: 1px solid #9A9A9A;
        border-top: none;
    }

    .fileImageText-link {
        height: 43px;
        background: #9A9A9A;
        display: block;
        text-decoration: none;
    }

    .fileImageText-link i {
        display: inline-block;
        vertical-align: top;
        padding-top: 14px;
        padding-left: 12px;
        padding-right: 4px;
        color: white;
    }

    .fileImageText-link-title {
        display: inline-block;
        vertical-align: top;
        padding-top: 12px;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-transform: uppercase;
    }

    .fileImageText-description {
        display: inline-block;
        vertical-align: top;
        margin-top: 30px;
        margin-left: 60px;
        font-family: "Istok Regular", sans-serif;
        line-height: 25px;
        width:440px;
    }

    .fileImageText-description strong {
        font-family: "Istok Bold", sans-serif;
        line-height: 25px;
    }

    .fileImageText-description p {
        margin-bottom: 10px;
    }

    .fileImageText-description ul {
        list-style: none;
    }

    .fileImageText-description ul li::before {
        content: "\2022";
        color: red;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: 0em;
        font-size: 26px;
    }

    .fileImageText-description ul li span {
        vertical-align: top;
    }
}

/*=== TABLET ===*/
@media screen and (min-width: 1022px) and (max-width: 1200px) {
    .fileImageTextContainer {
        border-bottom: 1px solid #9A9A9A;
        margin-bottom: 10px;
    }

    .fileContainerTitle {
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #E3001B;
        border-bottom: 1px solid #E3001B;
        padding-bottom: 11px;
    }

    .fileImageTextLeftSide {
        display: inline-block;
        max-width: 258px;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .fileImageTextLeftSide embed {
        display: block;
        border: none;
    }

    .fileImageText-download-container {
        border: 1px solid #9A9A9A;
        border-top: none;
    }

    .fileImageText-link {
        height: 43px;
        background: #9A9A9A;
        display: block;
        text-decoration: none;
    }

    .fileImageText-link i {
        display: inline-block;
        vertical-align: top;
        padding-top: 14px;
        padding-left: 12px;
        padding-right: 4px;
        color: white;
    }

    .fileImageText-link-title {
        display: inline-block;
        vertical-align: top;
        padding-top: 12px;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-transform: uppercase;
    }

    .fileImageText-description {
        display: inline-block;
        vertical-align: top;
        margin-top: 30px;
        margin-left: 60px;
        font-family: "Istok Regular", sans-serif;
        line-height: 25px;
        width:400px;
    }

    .fileImageText-description strong {
        font-family: "Istok Bold", sans-serif;
        line-height: 25px;
    }

    .fileImageText-description p {
        margin-bottom: 10px;
    }

    .fileImageText-description ul {
        list-style: none;
    }

    .fileImageText-description ul li::before {
        content: "\2022";
        color: red;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: 0em;
        font-size: 26px;
    }

    .fileImageText-description ul li span {
        vertical-align: top;
    }
}

@media screen and (min-width: 766px) and (max-width: 1022px) {
    .fileImageTextContainer {
        border-bottom: 1px solid #9A9A9A;
        margin-bottom: 10px;
    }

    .fileContainerTitle {
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #E3001B;
        border-bottom: 1px solid #E3001B;
        padding-bottom: 11px;
    }

    .fileImageTextLeftSide {
        display: inline-block;
        max-width: 258px;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .fileImageTextLeftSide embed {
        display: block;
        border: none;
        width:150px;
        height:215px;
    }

    .fileImageText-download-container {
        border: 1px solid #9A9A9A;
        border-top: none;
    }

    .fileImageText-link {
        height: 43px;
        background: #9A9A9A;
        display: block;
        text-decoration: none;
    }

    .fileImageText-link i {
        display: inline-block;
        vertical-align: top;
        padding-top: 14px;
        padding-left: 12px;
        padding-right: 4px;
        color: white;
    }

    .fileImageText-link-title {
        display: inline-block;
        vertical-align: top;
        padding-top: 12px;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-transform: uppercase;
    }

    .fileImageText-description {
        display: inline-block;
        vertical-align: top;
        margin-top: 30px;
        margin-left: 39px;
        font-family: "Istok Regular", sans-serif;
        line-height: 25px;
        width:300px;
    }

    .fileImageText-description strong {
        font-family: "Istok Bold", sans-serif;
        line-height: 25px;
    }

    .fileImageText-description p {
        margin-bottom: 10px;
    }

    .fileImageText-description ul {
        list-style: none;
    }

    .fileImageText-description ul li::before {
        content: "\2022";
        color: red;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: 0em;
        font-size: 26px;
    }

    .fileImageText-description ul li span {
        vertical-align: top;
    }
}

/*=== MOBILE ===*/
@media screen and (min-width: 478px) and (max-width: 766px) {
    .fileImageTextContainer {
        border-bottom: 1px solid #9A9A9A;
        margin-bottom: 10px;
        position:relative;
    }
    .fileImageTextContainer:last-child{
        border: none !important;
    }

    .fileContainerTitle {
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #E3001B;
        border-bottom: 1px solid #E3001B;
        padding-bottom: 11px;
    }

    .fileImageTextLeftSide {
        display: inline-block;
        max-width: 258px;
        margin-bottom: 20px;
    }

    .fileImageTextLeftSide embed {
        display: none;
        
    }

    .fileImageText-download-container {
        border-top: none;
        padding-top: 20px;
        position: absolute;
        bottom:0;
        right:0;
       
    }
    .fileImageText-download-container {

    }
    .fileImageText-link {
        height: 43px;
        display: block;
        text-decoration: none;
    }

    .fileImageText-link i {
        display: inline-block;
        vertical-align: top;
        padding-top: 14px;
        padding-left: 12px;
        padding-right: 4px;
        color: #9A9A9A;
        font-size:20px;
    }

    .fileImageText-link-title {
        display: inline-block;
        vertical-align: top;
        padding-top: 12px;
        color: #9A9A9A;
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 22px;
        text-transform: uppercase;
    }

    .fileImageText-description {
        display: block;
        vertical-align: top;
        font-family: "Istok Regular", sans-serif;
        line-height: 25px;
        padding-bottom:30px;
        width:300px;
    }

    .fileImageText-description strong {
        font-family: "Istok Bold", sans-serif;
        line-height: 25px;
    }

    .fileImageText-description p {
        margin-bottom: 10px;
    }

    .fileImageText-description ul {
        list-style: none;
    }

    .fileImageText-description ul li::before {
        content: "\2022";
        color: red;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: 0em;
        font-size: 26px;
    }

    .fileImageText-description ul li span {
        vertical-align: top;
    }
}

@media screen and (min-width: 350px) and (max-width: 478px) {
    .fileImageTextContainer {
        border-bottom: 1px solid #9A9A9A;
        margin-bottom: 10px;
        position: relative;
    }
    .fileImageTextContainer:last-child{
        border: none !important;
    }

    .fileContainerTitle {
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #E3001B;
        border-bottom: 1px solid #E3001B;
        padding-bottom: 11px;
    }

    .fileImageTextLeftSide {
        display: inline-block;
        max-width: 258px;
        margin-bottom: 20px;
    }

    .fileImageTextLeftSide embed {
        display: none;
        
    }

    .fileImageText-download-container {
        border-top: none;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    
    .fileImageText-link {
        height: 43px;
        display: block;
        text-decoration: none;
    }

    .fileImageText-link i {
        display: inline-block;
        vertical-align: top;
        padding-top: 14px;
        padding-right: 4px;
        color: #9A9A9A;
        font-size:20px;
    }

    .fileImageText-link-title {
        display: inline-block;
        vertical-align: top;
        padding-top: 12px;
        color: #9A9A9A;
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 22px;
        text-transform: uppercase;
    }

    .fileImageText-description {
        display: inline-block;
        vertical-align: top;
        font-family: "Istok Regular", sans-serif;
        line-height: 25px;
        padding-bottom:30px;
        width:300px;
    }

    .fileImageText-description strong {
        font-family: "Istok Bold", sans-serif;
        line-height: 25px;
    }

    .fileImageText-description p {
        margin-bottom: 10px;
    }

    .fileImageText-description ul {
        list-style: none;
        padding-bottom: 24px;
    }

    .fileImageText-description ul li::before {
        content: "\2022";
        color: red;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: 0em;
        font-size: 26px;
    }

    .fileImageText-description ul li span {
        vertical-align: top;
    }
}

@media screen and (max-width: 350px){
    .fileImageTextContainer {
        border-bottom: 1px solid #9A9A9A;
        margin-bottom: 10px;
    }
    .fileImageTextContainer:last-child{
        border: none !important;
    }

    .fileContainerTitle {
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #E3001B;
        border-bottom: 1px solid #E3001B;
        padding-bottom: 11px;
    }

    .fileImageTextLeftSide {
        display: inline-block;
        max-width: 258px;
        margin-bottom: 20px;
    }

    .fileImageTextLeftSide embed {
        display: none;
        
    }

    .fileImageText-download-container {
        border-top: none;
        padding-top: 20px;
       
    }
    .fileImageText-download-container {

    }
    .fileImageText-link {
        height: 43px;
        display: block;
        text-decoration: none;
    }

    .fileImageText-link i {
        display: inline-block;
        vertical-align: top;
        padding-top: 14px;
        padding-right: 4px;
        color: #9A9A9A;
        font-size:20px;
    }

    .fileImageText-link-title {
        display: inline-block;
        vertical-align: top;
        padding-top: 12px;
        color: #9A9A9A;
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 22px;
        text-transform: uppercase;
    }

    .fileImageText-description {
        display: inline-block;
        vertical-align: top;
        font-family: "Istok Regular", sans-serif;
        line-height: 25px;
        padding-bottom:30px;
        width:300px;
    }

    .fileImageText-description strong {
        font-family: "Istok Bold", sans-serif;
        line-height: 25px;
    }

    .fileImageText-description p {
        margin-bottom: 10px;
    }

    .fileImageText-description ul {
        list-style: none;
    }

    .fileImageText-description ul li::before {
        content: "\2022";
        color: red;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: 0em;
        font-size: 26px;
    }

    .fileImageText-description ul li span {
        vertical-align: top;
    }
}

@media print {
    .fileImageTextContainer {
        border-bottom: 1px solid #9A9A9A;
        margin-bottom: 10px;
    }

    .fileContainerTitle {
        font-family: "Istok Regular", sans-serif;
        font-size: 20px;
        line-height: 26px;
        color: #E3001B;
        border-bottom: 1px solid #E3001B;
        padding-bottom: 11px;
    }

    .fileImageTextLeftSide {
        display: inline-block;
        max-width: 258px;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .fileImageTextLeftSide embed {
        display: block;
        border: none;
    }

    .fileImageText-download-container {
        border: 1px solid #9A9A9A;
        border-top: none;
        padding-top: 20px;
    }

    .fileImageText-link {
        height: 43px;
        background: #9A9A9A;
        display: block;
        text-decoration: none;
    }

    .fileImageText-link i {
        display: inline-block;
        vertical-align: top;
        padding-top: 14px;
        padding-left: 12px;
        padding-right: 4px;
        color: white;
    }

    .fileImageText-link-title {
        display: inline-block;
        vertical-align: top;
        padding-top: 12px;
        color: white;
        font-family: "Istok Regular", sans-serif;
        font-size: 13px;
        line-height: 22px;
        text-transform: uppercase;
    }

    .fileImageText-description {
        display: inline-block;
        vertical-align: top;
        margin-top: 30px;
        margin-left: 60px;
        font-family: "Istok Regular", sans-serif;
        line-height: 25px;
    }

    .fileImageText-description strong {
        font-family: "Istok Bold", sans-serif;
        line-height: 25px;
    }

    .fileImageText-description p {
        margin-bottom: 10px;
    }

    .fileImageText-description ul {
        list-style: none;
    }

    .fileImageText-description ul li::before {
        content: "\2022";
        color: red;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: 0em;
        font-size: 26px;
    }

    .fileImageText-description ul li span {
        vertical-align: top;
    }
}